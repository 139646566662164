<template>
    <div v-if="!isPosted" class="elevation-0 rounded-lg" color="transparent" :loading="loading">
        <v-stepper v-model="e1">
            <v-stepper-header class="rounded-xl">
                <v-stepper-step :complete="e1 >= 1" step="1" color="primary lighten-1">
                    <span>{{ $t('Data') }}</span>
                </v-stepper-step>

                <v-divider />

                <v-stepper-step :complete="e1 > 2" step="2" color="primary lighten-1">
                    <span>{{ $t('Attendees') }}</span>
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="3" :complete="e1 > 3" color="primary lighten-1">
                    <span>{{ $t('Agenda') }}</span>
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="4" :complete="e1 > 4" color="primary lighten-1">
                    <span>{{ $t('Attachments') }}</span>
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="5" :complete="e1 > 5" color="primary lighten-1">
                    <span>{{ $t('AssociatedMeetings') }}</span>
                </v-stepper-step>
                <v-divider />
            </v-stepper-header>
        </v-stepper>

        <v-stepper v-model="e1" class="my-4">


            <v-stepper-items class="rounded-xl">
                <v-form ref="meetingForm" v-model="valid" lazy-validation>
                    <!--data-->
                    <v-stepper-content step="1" class="pb-5">

                        <MeetingMetaData v-model="meeting" :view-mode="meeting.readOnly" />
                        <div class="py-4">
                            <v-btn large color="primary btn-width" class="rounded-xl" v-if="!meeting.readOnly" :loading="btnLoading" @click="saveMeetingInfo(false)">
                                <span>{{ $t('Save') }}</span>
                            </v-btn>
                            <v-btn large color="#b0b7c3" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="saveMeetingInfo(true)">
                                <span>{{ $t('Next') }}</span>

                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <!--Attendees-->
                    <v-stepper-content step="2">
                        <v-card class="mb-8 elevation-0" tile min-height="58vh">
                            <MeetingAttendees v-model="meeting.meetingAttendees" :meeting-id="meetingId" :view-mode="meeting.readOnly" />
                        </v-card>
                        <div class="py-4">
                            <v-btn large color="primary btn-width" class="rounded-xl" :loading="btnLoading" @click="nextStep(0)">
                                <span>{{ $t('Previous') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-share
                                </v-icon>
                                <v-icon v-else>mdi-reply</v-icon>
                            </v-btn>
                            <v-btn large color="#b0b7c3" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="nextStep(2)">
                                <span>{{ $t('Next') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-reply
                                </v-icon>
                                <v-icon v-else>mdi-share</v-icon>
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <!--work programs-->
                    <v-stepper-content step="3">
                        <v-card class="mb-8 elevation-0" min-height="58vh">
                            <MeetingAgenda v-model="meeting.meetingAgendas" :view-mode="meeting.readOnly" :meeting-id="meetingId" :is-committee="meeting.isCommittee" :committee-id="meeting.committeeId" />
                        </v-card>
                        <div class="py-4">
                            <v-btn large color="primary btn-width" class="rounded-xl" :loading="btnLoading" @click="nextStep(1)">
                                <span> {{ $t('Previous') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-share
                                </v-icon>
                                <v-icon v-else>mdi-reply</v-icon>
                            </v-btn>
                            <v-btn large color="#b0b7c3" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="nextStep(3)">
                                <span> {{ $t('Next') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-reply
                                </v-icon>
                                <v-icon v-else>mdi-share</v-icon>
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <!--attachments-->
                    <v-stepper-content step="4">
                        <v-card class="mb-8 elevation-0" min-height="58vh">
                            <MeetingAttachments v-model="attachments"
                                                :meeting-agendas="meeting.meetingAgendas"
                                                :meeting-id="meetingId"
                                                :view-mode="meeting.readOnly" />
                        </v-card>
                        <div class="py-4">
                            <v-btn large color="primary btn-width" class="rounded-xl" :loading="btnLoading" @click="nextStep(2)">
                                <span>
                                    {{ $t('Previous') }}
                                </span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-share
                                </v-icon>
                                <v-icon v-else>mdi-reply</v-icon>
                            </v-btn>

                            <v-btn large color="#b0b7c3" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="nextStep(4)">
                                <span>  {{ $t('Next') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'">
                                    mdi-reply
                                </v-icon>
                                <v-icon v-else>mdi-share</v-icon>
                            </v-btn>
                        </div>
                    </v-stepper-content>

                    <!--related meetings-->
                    <v-stepper-content step="5">
                        <v-card class="mb-8 elevation-0" min-height="58vh">
                            <AssociatedMeetings v-model="meeting.associatedMeetings" :meeting-id="meetingId" :view-mode="meeting.readOnly" class="mt-4" :label="$t('Search')" :outlined="true" />
                        </v-card>
                        <div class="py-4">
                            <v-btn large color="primary btn-width" class="rounded-xl" @click="nextStep(3)">
                                <span>{{ $t('Previous') }}</span>
                                <v-icon v-if="$i18n.locale =='ar'" :loading="btnLoading">
                                    mdi-share
                                </v-icon>
                                <v-icon v-else>mdi-reply</v-icon>
                            </v-btn>
                            <v-btn v-if="!meeting.readOnly" large color="#456191" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="sendMeeting()">
                                <span>{{ $t('Send') }}</span>
                                <v-icon>mdi-transfer-up</v-icon>
                            </v-btn>
                            <v-btn v-if="!meeting.readOnly" large color="#f2af6f" class="rounded-xl ms-2 white--text btn-width" :loading="btnLoading" @click="approveMeeting()">
                                <span>{{ $t('MeetingDirectApproval') }}</span>
                                <v-icon>mdi-timer-play</v-icon>
                            </v-btn>
                        </div>
                    </v-stepper-content>
                </v-form>
            </v-stepper-items>
        </v-stepper>
        <v-card-text>

        </v-card-text>
    </div>

    <v-card v-else tile width="30%" class="mx-auto my-12 pb-4">
        <v-card-text class="pb-0 text-center">
            <v-icon color="teal" large>
                mdi-check-outline
            </v-icon>
            <h2 class="my-3">{{ $t('SuccessStartMeeting') }}</h2>
        </v-card-text>
        <v-card-actions class="pt-0 text-center">
            <v-btn class="mx-auto mt-3" color="primary" to="/">{{ $t('HomePage') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import MeetingMetaData from '@/components/app/meeting/MeetingMetaData.vue'
    import MeetingAttendees from '@/components/app/meeting/MeetingAttendees.vue'
    import MeetingAttachments from '@/components/app/meeting/MeetingAttachments.vue'
    import MeetingAgenda from '@/components/app/meeting/MeetingAgenda.vue'
    import AssociatedMeetings from '@/components/app/meeting/AssociatedMeetings.vue'
    import MeetingsService from '@/services/MeetingsService.js'
    export default {
        components: {
            MeetingMetaData, MeetingAttendees, MeetingAttachments, MeetingAgenda, AssociatedMeetings
        },

        data() {
            return {
                e1: 1,
                isPosted: false,
                meetingId: this.$route.params.id ? parseInt(this.$route.params.id) : null,
                valid: true,
                meeting: {
                    id: 0,
                    title: null,
                    isCommittee: false,
                    committeeId: null,
                    date: null,
                    startTime: null,
                    endTime: null,
                    location: null,
                    notes: null,
                    meetingAttendees: [],
                    meetingAgendas: [],
                    associatedMeetings: [],
                },
                attachments: [],
                loading: false,
                btnLoading: false,
                originalMeeting: null,
            }
        },

        watch: {
            steps(val) {
                if (this.e1 > val) {
                    this.e1 = val
                }
            },
            '$route.params.id': function (newId, oldId) {
                const meetingId = newId ? parseInt(newId) : null
                this.handleMeetingIdChange(meetingId)
            }
        },

        created() {
            if (this.meetingId) {
                this.meeting.id = this.meetingId
                this.load(this.meetingId)
            } else {
                this.resetPage()
            }
        },

        methods: {
            handleMeetingIdChange(meetingId) {
                if (meetingId) {
                    this.meeting.id = meetingId
                    this.load(meetingId)
                } else {
                    this.meetingId = meetingId
                    this.resetPage()
                }
            },
            nextStep(n) {
                if (this.$refs.meetingForm.validate()) {
                    if (n == 2) {
                        if (this.meeting.meetingAttendees.length == 0) {
                            this.$root.$notification(this.$t('AddAttendees'), 'error')
                            return
                        }
                    }

                    if (n == 3) {
                        if (this.meeting.meetingAgendas.length == 0) {
                            this.$root.$notification(this.$t('AddAgendas'), 'error')
                            return
                        }
                    }

                    if (n === this.steps) {
                        this.e1 = 1
                    } else {
                        this.e1 = n + 1
                    }
                }
            },

            saveMeetingInfo(stepNext) {
                if (this.meeting.readOnly) {
                    if (stepNext) {
                        this.nextStep(1)
                    }
                    return
                }
                if (!this.$refs.meetingForm.validate()) return
                if (this.meetingId) {
                    var changed = JSON.stringify(this.meeting) == JSON.stringify(this.originalMeeting)


                    if (changed) {

                        if (stepNext) {
                            this.nextStep(1)
                        } else {
                            this.$root.$notification(this.$t('NoChangesMade'), 'info')
                        }

                        return
                    }
                    this.btnLoading = true

                    MeetingsService.updateMeetingInfo(this.meeting).then((res) => {
                        if (res.data) {

                            for (let key in res.data) {
                                if (res.data.hasOwnProperty(key) && this.meeting.hasOwnProperty(key)) {
                                    this.meeting[key] = res.data[key]
                                }
                            }
                            this.originalMeeting = { ...this.meeting }
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')

                            if (stepNext) {
                                this.nextStep(1)
                            }
                        }
                        else {
                            this.$root.$notification(this.$t('UpdateFailed'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.btnLoading = false
                    })
                } else {
                    this.btnLoading = true
                    MeetingsService.saveMeetingInfo(this.meeting).then((res) => {
                        if (res.data) {
                            for (let key in res.data) {
                                //update only the info fields so we dont lose other data (agenda ,attendees ...)
                                if (res.data.hasOwnProperty(key) && this.meeting.hasOwnProperty(key)) {
                                    this.meeting[key] = res.data[key]
                                }

                            }
                            this.meetingId = res.data.id
                            this.originalMeeting = { ...this.meeting }
                            this.$router.replace({ name: 'addMeeting', params: { id: this.meetingId } })
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                            if (stepNext) {
                                this.nextStep(1)
                            }
                        }
                        else {
                            this.$root.$notification(this.$t('UpdateFailed'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.btnLoading = false
                    })
                }


            },
            sendMeeting() {
                this.btnLoading = true
                MeetingsService.sendMeeting(this.meetingId).then((res) => {
                    if (res.success) {
                        this.isPosted = true
                    }
                    else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.btnLoading = false
                })

            },
            approveMeeting() {
                this.btnLoading = true
                MeetingsService.approveMeeting(this.meetingId).then((res) => {
                    if (res.success) {
                        this.isPosted = true
                    }
                    else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.btnLoading = false
                })

            },
            load(meetingId) {
                this.loading = true
                MeetingsService.loadMeeting(meetingId).then((res) => {
                    if (res.data) {
                        this.attachments = res.data.attachments
                        this.meeting = res.data
                        this.originalMeeting = { ...this.meeting }
                        let readOnlyParam = this.$route.params.viewMode
                        if (readOnlyParam) {
                            this.meeting.readOnly = readOnlyParam
                        }
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            resetPage() {
                if (this.$refs.meetingForm) {//hide validation messages on reset page
                    this.$refs.meetingForm.resetValidation()
                }

                this.originalMeeting = null
                this.attachments = []
                this.meeting = {
                    id: 0,
                    title: null,
                    isCommittee: false,
                    committeeId: null,
                    date: null,
                    startTime: null,
                    endTime: null,
                    location: null,
                    notes: null,
                    meetingAttendees: [],
                    meetingAgendas: [],
                    associatedMeetings: [],
                }
            }
        },
    }
</script>

<style>
</style>