<template>
    <div>
        <v-card :loading="loading">
            <div class="card-title ">
                <div>
                    {{ $t('Users') }}
                </div>
            </div>
            <GridWithServerPaging :headers="headers" :items="members" :items-per-page="options.itemsPerPage" :total="totalCount" disable-sort class="mt-4" @onChange="loadMembers" />
        </v-card>
    </div>
</template>

<script>
    import CouncilCommitteesService from '@/services/CouncilCommitteesService'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default ({
        components: {
             GridWithServerPaging
        },

        props: {
            id: {
                type: Number,
                default:null
            },
        },
        data() {
            return {
                loading: false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('FullName'), value: 'fullname' },
                    { text: this.$t('Role'), value: 'roleName' },
                    { text: this.$t('Email'), value: 'email' },
                ],
                members: [],
                options: {
                    page: 1, itemsPerPage: 5
                },
                totalCount: null,
            }
        },
        created() {
        },

        mounted() {
            this.loadMembers(this.options)
        },
        methods: {
            loadMembers(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                CouncilCommitteesService.listUsersInCouncilCommitteeGeneralInfo(this.id, page, pageSize).then((res) => {
                    this.members = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>