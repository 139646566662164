import moment from 'moment'
import store from '@/plugins/store/index.js'
export default {
	install(Vue) {
		Vue.filter('formatDateTimeGregorian', function (value) {
			const defaultLanguage = store.getters['user/language']
			if (!value) return ''
			if (defaultLanguage == 'ar') {
				return new Date(value).toLocaleDateString('ar-SA', {
					year: 'numeric', month: 'long', day: 'numeric', hour12: true,
					hour: '2-digit',
					minute: '2-digit'
				})
			}
			else {
				return new Date(value).toLocaleString('en', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour12: true,
					hour: '2-digit',
					minute: '2-digit',
				})
			}
		})
		Vue.filter('formatDateGregorian', function (value) {
			if (!value) return ''
			const defaultLanguage = store.getters['user/language']
			return  new Date(value).toLocaleString('en', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			})
		})

		Vue.filter('trim', function (value, arg1) {

			const trimSize = (arg1 || 15)
			if (value && value.length > trimSize) {
				return value.substring(0, trimSize) + '...'
			}
			return value
		})

		Vue.filter('formatDateAr', function (value) {
			if (!value) return ''
			return new Date(value).toLocaleDateString('ar-SA', { year: 'numeric', month: 'long', day: 'numeric' })
		})

		Vue.filter('formatDateWithTimeGregorian', function (value) {
			if (!value) return ''
			return new Date(value).toLocaleString('en', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour12: true,
				hour: '2-digit',
				minute: '2-digit',
			})
		})
		Vue.filter('formatDateWithTimeAr', function (value) {
			if (!value) return ''
			return new Date(value).toLocaleString('ar-SA', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			})
		})
	}
}

