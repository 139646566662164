<template>
    <div>
        <v-row>
            <v-col cols="3" v-for="(item, index) in meetingStatusesLabels" :key="item">
                <v-card class="rounded-lg custom-elevation" hover>
                    <v-card-text class="pa-1">
                        <v-list-item-avatar tile size="80" class="ma-0">
                            <v-btn dark :color="colors[index]" fab>
                                <v-icon large>{{icons[index]}}</v-icon>
                            </v-btn>
                        </v-list-item-avatar>
                        <v-list-item two-line class="text-center">
                            <v-list-item-content class="pt-0">
                                <div class=" mb-4" style="color: #999999; font-size:30px">
                                    {{ meetingsSeries[0].data[index] }}
                                </div>
                                <div class="mb-1">
                                    <span class="mms-title"> {{item}}</span>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-card class="custom-elevation">
                    <v-card-title> {{$t('CommitteesAndCouncilsCount')}}</v-card-title>
                    <hr class="custom-separator" />
                    <Doughnut v-if="councilsAndComitteesData" :type="doghnutType" :labels="councilsAndComitteesLabels" :height="220" :series="councilsAndComitteesData" />
                </v-card>
                <v-card class="custom-elevation mt-3" v-for="(item, index) in recommendationsLabels" :key="'recommendation' + item">
                    <v-card-text v-if="recommendationsData">
                        <v-row>
                            <v-col cols="9">{{item}}</v-col>
                            <v-col cols="3" class="text-end">{{ recommendationsData[index] }} %</v-col>
                            <v-col cols="12"><v-progress-linear height="7" :color="progressColors[index]" :value="recommendationsData[index]"></v-progress-linear></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="custom-elevation mt-3" v-for="(item, index) in tasksLabels" :key="'task' + item">
                    <v-card-text v-if="tasksData">
                        <v-row>
                            <v-col cols="9">{{item}}</v-col>
                            <v-col cols="3" class="text-end">{{ tasksData[index] }} %</v-col>
                            <v-col cols="12"><v-progress-linear height="7" :color="progressColors[index]" :value="tasksData[index]"></v-progress-linear></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card class="custom-elevation">
                    <v-card-title>{{ $t('UsersStatus') }}</v-card-title>
                    <v-container class="pa-0">
                        <hr class="custom-separator" />
                        <v-card-text v-if="usersData">
                            <v-row class="no-gutters">
                                <v-col>
                                    <img src="@/assets/images/users.png" />
                                </v-col>
                                <v-col class="text--primary">{{ $t('ActiveUsers') }}</v-col>
                                <v-col>{{ usersData[1] }}</v-col>
                                <v-col class="text-end me-8"><v-badge dot color="green"></v-badge></v-col>
                            </v-row>
                            <hr class="custom-separator" />
                            <v-row class="pt-4">
                                <v-col>
                                    <img src="@/assets/images/users.png" />
                                </v-col>
                                <v-col class="text--primary">{{ $t('InactiveUsers') }}</v-col>
                                <v-col>{{ usersData[0] }}</v-col>
                                <v-col class="text-end me-8"><v-badge dot color="grey"></v-badge></v-col>
                            </v-row>
                        </v-card-text>
                    </v-container>
                </v-card>
                <v-card class="mt-4 custom-elevation">
                    <v-card-title> {{$t('CommitteesAndCouncilsCount')}}</v-card-title>
                    <hr class="custom-separator" />
                    <Column :height="325"></Column>
                    <!--<BarChart v-if="meetingsSeries" :labels="meetingStatusesLabels" :show-bottom-labels="showBottomLabels" :title="$t('MeetingStatuses')" :height="370" :series="meetingsSeries" />-->
                </v-card>
            </v-col>
          
        </v-row>
    </div>
</template>
<script>
    import Column from '@/components/app/charts/Column'
    import Doughnut from '@/components/app/charts/Doghnut'
    import DashboardService from '@/services/DashboardService.js'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            Doughnut, Column
        },
        data() {
            return {
                loading: false,
                dataLoaded: false,
                showBottomLabels: true,
                councilsAndComitteesLabels: [
                    this.$t('Council'),
                    this.$t('Comittee')
                ],
                recommendationsLabels: [
                    this.$t('CompletedRecommendations'),
                    this.$t('IncompletedRecommendations')
                ],
                tasksLabels: [
                    this.$t('CompletedTasks'),
                    this.$t('IncompleteTasks')
                ],
                usersLabels: [
                    this.$t('ActiveUsers'),
                    this.$t('InactiveUsers')
                ],
                meetingMinutesLabels: [
                    this.$t('InitialMeetingsMinutes'),
                    this.$t('FinalMeetingsMinutes')
                ],
                meetingStatusesLabels: [
                ],
                doghnutType: 'pie',
                meetingsSeries: null,
                councilsAndComitteesData: null,
                recommendationsData: null,
                tasksData: null,
                usersData: null,
                meetingsMinutesData: null,
                icons: ['mdi-text-box-edit', 'mdi-list-box-outline', 'mdi-account-group', 'mdi-briefcase-remove', 'mdi-account-clock', 'mdi-check-decagram', 'mdi-draw-pen', 'mdi-file-sign'],
                colors: ['warning', '#456191', '#4fbab0', '#6f9ff2', '#6f9ff2', '#4fbab0', '#456191', 'warning'],
                progressColors: ['#52dbce', '#dd0023']
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),
            isArabicLanguage() {
                return this.language == 'ar'
            },
        },
        
        mounted() {
            this.loading = true
            let promises = [
                DashboardService.getCouncilsAndCoumitteesCounts(),
                DashboardService.getRecommendationsCounts(),
                DashboardService.getTasksCounts(),
                DashboardService.getUsersCounts(),
                DashboardService.getMeetingMinutesCounts(),
                DashboardService.getMeetingsCounts(),
            ]

            Promise.all(promises)
                .then(
                    ([
                        councilsAndComitteesCount,
                        recommendationsCount,
                        tasksCount,
                        usersCount,
                        meetingsMinutesCount,
                        meetingsCount,
                    ]) => {

                        this.councilsAndComitteesData = councilsAndComitteesCount.data.values
                        this.recommendationsData = recommendationsCount.data.values
                        this.tasksData = tasksCount.data.values
                        this.usersData = usersCount.data.values
                        this.meetingsMinutesData = meetingsMinutesCount.data.values
                        this.meetingStatusesLabels = meetingsCount.data.labels
                        this.meetingsSeries = meetingsCount.data.series

                    },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
        },
        
    })
</script>

<style>
</style>