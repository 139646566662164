<template>
    <v-card tile :loading="loading" class="rounded-lg">

        <v-card-text>
            <v-row justify="space-between">
                <v-col cols="3">
                    <v-row>
                        <v-col cols="6">
                            <v-btn class="mx-2 my-2" fab dark small color="primary" @click="addNewCouncilCommittee">
                                <v-icon>mdi-folder-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-text-field v-model="search" class="py-3" outlined :label="$t('Search')" dark flat solo-inverted hide-details clearable clear-icon="mdi-close-circle-outline" @input="handleSearch" />
                    <v-treeview color="grey" ref="tree" :items="result" :search="search" :filter="filter" activatable item-key="id" :open.sync="open">
                        <template v-slot:prepend="{ open :isOpen }">
                            <v-icon>
                                {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <a class="d-block pa-2" @click="viewDetails(item)">
                                {{ item.name }}
                            </a>
                        </template>
                    </v-treeview>
                </v-col>
                <v-divider vertical />

                <v-col>
                    <v-scroll-y-transition mode="out-in">
                        <div v-if="show">
                            <v-row>
                                <v-col cols="12" md="9">
                                    <h3>{{ selectedTitle }}</h3>
                                </v-col>
                                <v-col cols="12" md="3" class="text-end">
                                    <v-btn color="warning" class="ms-3 white--text" @click="editCouncilCommittee">
                                        {{ $t('Edit') }}
                                        <v-icon small class="ms-2">mdi-pencil</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-card-text tile class="mt-6">
                                <v-tabs v-model="tab" grow background-color="#fff" dark slider-color="warning" slider-size="5">
                                    <v-tab class="black--text">
                                        <v-icon class="my-2 primary--text">
                                            mdi-account
                                        </v-icon>
                                        {{ $t('Users') }}
                                    </v-tab>
                                    <v-tab @click="listCommitteeDuties" class="black--text">
                                        <v-icon class="my-2 primary--text">
                                            mdi-lock
                                        </v-icon>
                                        {{ $t('Tasks') }}
                                    </v-tab>
                                    <v-tab class="black--text">
                                        <v-icon class="my-2 primary--text">
                                            mdi-access-point
                                        </v-icon>
                                        {{ $t('Meetings') }}
                                    </v-tab>
                                    <!-- users-->
                                    <v-tab-item>
                                        <v-card-text>
                                            <v-data-table dense :headers="headers" :items="users" :items-per-page="10">
                                                <template v-slot:top>
                                                    <v-btn class="mx-2 my-2 float-end" fab dark small color="primary" @click="openUserAddDialog()">
                                                        <v-icon>mdi-account-plus-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:[`item.active`]="{ item }">
                                                    <v-icon v-if="item.active" color="primary">
                                                        mdi-check
                                                    </v-icon>
                                                    <v-icon v-else color="error">
                                                        mdi-close
                                                    </v-icon>
                                                </template>
                                                <template v-slot:[`item.options`]="{ item }">
                                                    <v-icon color="error" @click="removeUserFromCouncilCommittee(item.id)">mdi-delete</v-icon>
                                                    <v-icon color="primary" class="mx-2" @click="openEditUserCommitteePermission(item.id)">mdi-shield-half-full</v-icon>

                                                    <v-icon color="primary" @click="openUserEditDialog(item)">mdi-pencil</v-icon>

                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                    </v-tab-item>
                                    <!-- duties-->
                                    <v-tab-item>
                                        <v-card>
                                            <v-card-text>
                                                <v-btn class="mx-2 my-2 float-end" fab dark small color="primary" @click="openDutyAddDialog">
                                                    <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
                                                </v-btn>

                                                <v-data-table :headers="dutyHeaders" :items="duties" :items-per-page="10" :footer-props="footerProps">
                                                    <template v-slot:[`item.options`]="{ item }">
                                                        <v-icon color="secondary" class="ms-3" @click="editDuty(item)">mdi-pencil</v-icon>
                                                        <v-icon class="ms-3" color="error" @click="removeDuty(item.id)">mdi-trash-can-outline</v-icon>
                                                    </template>
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <!-- last-->
                                    <v-tab-item>
                                        <v-card class="mt-10 elevation-0">
                                            <v-card-text>

                                                <GridWithServerPaging dense :headers="meetingsHeaders" :items="meetings" :items-per-page="meetingOptions.itemsPerPage" :total="meetingTotalCount" @onChange="listMeetingsInCouncilCommittee">
                                                    <template v-slot:[`item.date`]="{ item }">
                                                        {{ item.date| formatDateAr }}
                                                    </template>
                                                    <template v-slot:[`item.options`]="{ item }">
                                                        <v-icon v-if="item.statusId>1" color="secondary" class="ms-3" @click="viewMeeting(item)">mdi-eye</v-icon>
                                                    </template>
                                                </GridWithServerPaging>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>
                                </v-tabs>
                            </v-card-text>
                        </div>
                        <div v-if="!show">
                            <v-card-text class="text-center">
                                <div>{{ $t('NoItemsToShow') }}</div>
                                <img src="@/assets/images/empty.png" width="50%">
                            </v-card-text>
                        </div>
                    </v-scroll-y-transition>
                </v-col>
            </v-row>
        </v-card-text>

        <v-form ref="userInCommitteeForm" v-model="valid" lazy-validation>
            <CommonDialog v-if="userAddDialog" v-model="userAddDialog" :fullscreen="false" width="30%" :title="$t('AddUser')" show-action-toolbar>
                <v-container>
                    <v-row class="mt-2 mb-2">
                        <v-col cols="6">
                            <v-switch v-model="userActiveInCommittee" :label="$t('Active')" color="teal" />
                        </v-col>
                        <v-col cols="6">
                            <UserAutocomplete outlined :disabled="userEditDialog" v-model="selectedUserId" :rules="nameRules" :label="$t('SelectUserSearch')" class="mt-4 mb-0" />
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete outlined v-model="selectedRoleId" :rules="nameRules" item-text="name" item-value="id" :items="roles" :label="$t('SelectRole')" />
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="selectedPrivacyId" :rules="nameRules" outlined item-text="name" item-value="id" :items="privacies" :label="$t('PrivacyLevel')" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="userNote" outlined :label="$t('Comment')" />
                        </v-col>
                    </v-row>
                </v-container>
                <template v-slot:actions>
                    <v-btn v-if="userEditDialog" color="secondary" class="white--text" @click="saveUserToCommittee">
                        {{ $t("Update") }}
                    </v-btn>
                    <v-btn v-else color="secondary" class="white--text" @click="saveUserToCommittee">
                        {{ $t("Add") }}
                    </v-btn>
                    <v-btn color="error" class="white--text" @click="userAddDialog = false; selectedRoleId= null">
                        {{ $t("Cancel") }}
                    </v-btn>
                </template>
            </CommonDialog>
        </v-form>

        <v-form ref="committeeDutiesForm" v-model="valid" lazy-validation>
            <CommonDialog v-if="dutyAddDialog" v-model="dutyAddDialog" :fullscreen="false" width="30%" :title="$t('Tasks')" show-action-toolbar>
                <v-container>
                    <v-row class="mt-2 mb-2">
                        <v-col cols="12">
                            <v-text-field outlined v-model="duty.title" :rules="cleanRequiredStringRules" :label="$t('Title')" clearable />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="duty.description" :rules="cleanStringRules" outlined :label="$t('Description')" rows="3" value="duty.description" />
                        </v-col>
                    </v-row>
                </v-container>
                <template v-slot:actions>
                    <v-btn v-if="!isEditCommitteeDuty" color="secondary" class="white--text" @click="saveCommitteeDuty">
                        {{ $t("Add") }}
                    </v-btn>
                    <v-btn v-if="isEditCommitteeDuty" color="secondary" class="white--text" @click="updateCommitteeDuty">
                        {{ $t("Edit") }}
                    </v-btn>
                    <v-btn color="error" class="white--text" @click="dutyAddDialog = false">
                        {{ $t("Cancel") }}
                    </v-btn>
                </template>
            </CommonDialog>
        </v-form>


        <v-dialog v-if="meetingDialogueOpened" v-model="meetingDialogueOpened" fullscreen hide-overlay persistent>
            <live-meeting :meeting="selectedMeeting" :view-mode="meetingViewMode" @close="meetingDialogueOpened = false" />
        </v-dialog>
        <CouncilCommiteeForm v-if="councilCommitteeDialog" v-model="councilCommitteeDialog" :council-committee-id="selectedCouncilCommitteeId" :edit="isEditCouncilCommittee" @update="refresh" />
        <UserCommitteePermissions v-if="committeePermissionDialogue" v-model="committeePermissionDialogue" :committee-id="selectedCouncilCommitteeId" :user-id="selectedUserId" />
    </v-card>
</template>

<script>
    import CouncilCommiteeForm from '@/components/app/admin/CouncilCommiteeForm'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'
    import LookupsService from '@/services/LookupsService.js'
    import MeetingsService from '@/services/MeetingsService.js'
    import UserAutocomplete from '@/components/controls/UserSelect'
    import CommonDialog from '@/components/controls/CommonDialog'
    import LiveMeeting from '@/components/app/meeting/MeetingDashboard.vue'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'
    import UserCommitteePermissions from '@/components/app/admin/UserCommitteePermission.vue'

    export default {
        components: {
            CouncilCommiteeForm, UserAutocomplete, CommonDialog, LiveMeeting, GridWithServerPaging, UserCommitteePermissions
        },

        data() {
            return {
                meetingDialogueOpened: false,
                meetingViewMode: true,
                valid: true,
                onlyActive: true,
                show: false,
                loading: false,
                councilCommitteeDialog: false,
                userAddDialog: false,
                committeePermissionDialogue: false,
                dutyAddDialog: false,
                isEditCouncilCommittee: false,
                isEditCommitteeDuty: false,
                selectedCouncilCommitteeId: null,
                selectedCommitteeDutyId: null,
                selectedTitle: null,
                selectedMeeting: null,
                search: null,
                tab: null,
                result: [],
                users: [],
                roles: [],
                privacies: [],
                duties: [],
                meetings: [],
                open: [1, 2],
                selectedUserId: null,
                selectedRoleId: null,
                selectedPrivacyId: null,
                userActiveInCommittee: false,
                userNote: null,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('FullName'), value: 'fullname' },
                    { text: this.$t('Role'), value: 'roleName' },
                    { text: this.$t('Email'), value: 'email' },
                    { text: this.$t('Active'), value: 'active' },
                    { text: this.$t('PrivacyLevel'), value: 'privacyName' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                dutyHeaders: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Title'), value: 'title' },
                    { text: this.$t('Description'), value: 'description' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right'
                },
                meetingsHeaders: [
                    { text: this.$t('Id'), value: 'id', sortable: false },
                    { text: this.$t('Title'), value: 'title', sortable: false },
                    { text: this.$t('Status'), value: 'statusName', sortable: false },
                    { text: this.$t('MeetingRoom'), value: 'location', sortable: false },
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber', sortable: false },
                    { text: this.$t('Date'), value: 'date', sortable: false },
                    { text: this.$t('Options'), value: 'options', sortable: false },
                ],
                meetingOptions: {
                    page: 1, itemsPerPage: 5
                },
                meetingTotalCount: 0,
                duty: {
                    title: null,
                    description: ''
                },
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                cleanStringRules: [this.$rules.onlyAlphanumericRule],

                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },

        computed: {
            filter() {
                return this.caseSensitive
                    ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                    : undefined
            },
        },

        watch: {
            userAddDialog: {
                handler: function (open) {
                    if (open) {
                        this.listCommitteeRoles()
                        this.listPrivacies()
                    }
                }
            }
        },

        created() {
            this.listCouncilsAndCommitteesStructure()
        },

        methods: {

            handleSearch(input) {
                if (input) {
                    this.$refs.tree.updateAll(true)
                } else {
                    this.$refs.tree.updateAll(false)
                }
            },

            listCouncilsAndCommitteesStructure() {
                CouncilCommitteesService.listOrganization(this.onlyActive).then((res) => {
                    if (res.data) {
                        this.result = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            addNewCouncilCommittee() {
                this.isEditCouncilCommittee = false
                this.councilCommitteeDialog = true
            },

            refresh(value, title) {
                if (value) {
                    this.selectedTitle = title
                    this.listCouncilsAndCommitteesStructure()
                }
            },

            viewDetails(item) {
                if (item.id) {
                    this.show = true
                    this.tab = null,
                        this.selectedCouncilCommitteeId = item.id
                    this.selectedTitle = item.name
                    this.listUsersInCouncilCommittee(item.id)
                    this.listMeetingsInCouncilCommittee()
                }
            },

            editCouncilCommittee() {
                this.isEditCouncilCommittee = true
                this.councilCommitteeDialog = true
            },

            openUserAddDialog() {
                this.selectedUserId = null
                this.selectedRoleId = null
                this.userNote = null
                this.userActiveInCommittee = false
                this.selectedPrivacyId = null
                this.userAddDialog = true
                this.userEditDialog = false
            },
            openUserEditDialog(user) {
                this.selectedUserId = user.id
                this.selectedRoleId = user.committeeRoleId
                this.selectedPrivacyId = user.privacyId
                this.userActiveInCommittee = user.active
                this.userNote = user.note
                this.userAddDialog = true
                this.userEditDialog = true
            },
            openEditUserCommitteePermission(userId) {
                this.selectedUserId = userId
                this.committeePermissionDialogue = true
            },

            openDutyAddDialog() {
                this.duty.title = null
                this.duty.description = ''
                this.isEditCommitteeDuty = false
                this.dutyAddDialog = true
            },

            listCommitteeRoles() {
                if (this.roles && this.roles.length > 0) {
                    return
                }
                LookupsService.listCommitteeRoles().then((res) => {
                    if (res.data) {
                        this.roles = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            listPrivacies() {
                if (this.privacies && this.privacies.length > 0) {
                    return
                }
                LookupsService.listPrivacies().then((res) => {
                    if (res.data) {
                        this.privacies = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },
            saveUserToCommittee() {
                if (this.$refs.userInCommitteeForm.validate()) {
                    if (this.userEditDialog) {
                        let formData = {
                            userId: this.selectedUserId,
                            committeeRoleId: this.selectedRoleId,
                            privacyId: this.selectedPrivacyId,
                            note: this.userNote,
                            active: this.userActiveInCommittee,

                        }
                        CouncilCommitteesService.updateCommitteeUser(this.selectedCouncilCommitteeId, formData).then((res) => {
                            if (res.success) {
                                this.userAddDialog = false
                                this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                                this.listUsersInCouncilCommittee(this.selectedCouncilCommitteeId)
                            } else {
                                this.$root.$notification(this.$t('NotUpdated'), 'info')

                            }
                        }).catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => {
                            this.loading = false
                        })

                    }
                    else {
                        let formData = {
                            committeeId: this.selectedCouncilCommitteeId,
                            userId: this.selectedUserId,
                            committeeRoleId: this.selectedRoleId,
                            privacyId: this.selectedPrivacyId,
                            note: this.userNote,
                            active: this.userActiveInCommittee,

                        }
                        CouncilCommitteesService.addUserToCouncilCommittee(formData).then((res) => {
                            if (res.success) {
                                this.userAddDialog = false
                                this.listUsersInCouncilCommittee(this.selectedCouncilCommitteeId)
                            } else {
                                this.$root.$notification(res.message, 'error')

                            }
                        }).catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => {
                            this.loading = false
                        })
                    }

                }
            },

            listUsersInCouncilCommittee(councilCommitteeId) {
                CouncilCommitteesService.listUsersInCouncilCommittee(councilCommitteeId).then((res) => {
                    if (res.data) {
                        this.users = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            listMeetingsInCouncilCommittee(options) {
                const page = options != undefined ? options.page : this.meetingOptions.page
                const pageSize = options != undefined ? options.itemsPerPage : this.meetingOptions.itemsPerPage
                let formData = {
                    statusId: null,
                    committeeId: this.selectedCouncilCommitteeId,
                    meetingId: null,
                    fromDate: null,
                    toDate: null,
                    location: null,
                    title: null,
                    onlyMyMeetings: false,
                    includeDrafts: true,
                }
                this.loading = true
                MeetingsService.searchUserMeetings(formData, page, pageSize).then((res) => {
                    if (res.data) {
                        this.meetings = res.data.data
                        this.meetingTotalCount = res.data.total

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            async removeUserFromCouncilCommittee(userId) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    CouncilCommitteesService.removeUserFromCouncilCommittee(this.selectedCouncilCommitteeId, userId)
                        .then(() => {
                            this.listUsersInCouncilCommittee(this.selectedCouncilCommitteeId)
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },


            async removeDuty(dutyId) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    CouncilCommitteesService.removeDuty(dutyId)
                        .then(() => {
                            this.listCommitteeDuties()
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            saveCommitteeDuty() {
                if (this.$refs.committeeDutiesForm.validate()) {
                    CouncilCommitteesService.addCommitteeDuty(this.selectedCouncilCommitteeId, this.duty).then((res) => {
                        if (res.success) {
                            this.resetDuty()
                            this.listCommitteeDuties()
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            resetDuty() {
                this.duty.title = null
                this.duty.description = ''
                this.$refs.committeeDutiesForm.reset()
            },

            listCommitteeDuties() {
                CouncilCommitteesService.listCommitteeDuties(this.selectedCouncilCommitteeId).then((res) => {
                    if (res.data) {
                        this.duties = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            editDuty(item) {
                this.duty.title = item.title
                this.duty.description = item.description
                this.selectedCommitteeDutyId = item.id
                this.isEditCommitteeDuty = true
                this.dutyAddDialog = true
            },

            updateCommitteeDuty() {
                if (this.$refs.committeeDutiesForm.validate()) {
                    CouncilCommitteesService.updateCommitteeDuty(this.selectedCommitteeDutyId, this.duty).then((res) => {
                        if (res.success) {
                            this.resetDuty()
                            this.listCommitteeDuties()
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
            viewMeeting(meeting) {
                this.selectedMeeting = meeting
                this.meetingDialogueOpened = true

            }
        },
    }
</script>
