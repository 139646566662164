<template>
    <div>
        <v-card class="mb-5 rounded-lg">
            <v-card-title>{{$t('Search') }}</v-card-title>
            <hr class="custom-separator" />
            <v-card-text class="mt-4">
                <v-row>
                    <v-col v-if="isAdmin" cols="3">
                        <v-checkbox v-model="searchModel.onlyMyMeetings" hide-details :label="$t('OnlyMyMeetings')" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.meetingId"
                                       outlined
                                      type="number"
                                      :label="$t('MeetingNumber')" />
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="searchModel.statusId"
                                  :items="statusItems"
                                  outlined
                                  
                                  :label="$t('Status')"
                                  item-text="name"
                                  clearable
                                  item-value="id" />
                    </v-col>

                    <v-col cols="3">
                        <v-autocomplete v-model="searchModel.committeeId" 
                                        outlined
                                        :loading="loading"
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        :items="committeeItems"
                                        :label="$t('Committees')"
                                        @change="addAttendees($event)" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.location" 
                                      outlined
                                      :label="$t('MeetingRoom')" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.title"
                                      
                                      outlined
                                      :label="$t('Subject')" />
                    </v-col>
                    <v-col cols="3">
                        <DatePicker v-model="searchModel.fromDate"
                                    
                                    :outlined="true"
                                    :label="$t('FromDate')" />
                    </v-col>
                    <v-col cols="3">
                        <DatePicker v-model="searchModel.toDate"
                                    
                                    :outlined="true"
                                    :label="$t('ToDate')" />
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-btn :disabled="loading"
                               class="me-1 pt-0 white--text rounded-xl"
                               color="primary"
                               type="submit"
                               @click="listUserMeetings()">
                            {{ $t('Search') }}
                            <v-icon class="ms-2">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn :disabled="loading"
                               class="ms-2 white--text rounded-xl"
                               color="grey"
                               @click="reset()">
                            {{ $t('Reset') }}
                            <v-icon class="ms-2">mdi-refresh</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class="my-3 rounded-lg">
            <v-card-title>{{ $t('Meetings') }}</v-card-title>
            <hr class="custom-separator" />
            <v-card-text>
                <GridWithServerPaging :headers="headers" :disableSort="true" :items="meetings" :total="totalCount" @onChange="listUserMeetings" :items-per-page="options.itemsPerPage">
                    <template v-slot:top>
                        <v-text-field outlined class="rounded-lg" v-model="search" :label="$t('Search')" append-icon="mdi-magnify" />
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                        {{ item.date | formatDateAr }}
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        {{ item.title | trim(60) }}
                    </template>
                    <template v-slot:[`item.startTime`]="{ item }">
                        <v-chip class="ma-2" small color="primary" text-color="white">
                            {{ item.startTime }}
                            <v-icon right small>
                                mdi-timer-check-outline
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.endTime`]="{ item }">
                        <v-chip class="ma-2" color="error" small text-color="white" width="250">
                            {{ item.endTime }}
                            <v-icon right small>
                                mdi-timer-pause-outline
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.statusId`]="{ item }">
                        <v-progress-circular :rotate="-90" :size="35" :width="3" :value="getMeetingProgress(item.statusId)" color="primary">
                            <h6>{{ getMeetingProgress(item.statusId) }}</h6>
                        </v-progress-circular>
                    </template>
                    <template v-slot:[`item.attendees`]="{ item }">
                        <v-icon @click="selectedMeetingId = item.id; attendeesDialog= true">mdi-account-box-multiple-outline</v-icon>
                    </template>
                    <template v-slot:[`item.agenda`]="{ item }">
                        <v-icon @click="selectedMeetingId = item.id; agendasDialog= true">mdi-view-grid</v-icon>
                    </template>
                    <template v-slot:[`item.preview`]="{ item }">
                        <v-btn icon @click="viewMeeting(item)">
                            <v-icon color="primary"> mdi-monitor-account</v-icon>
                        </v-btn>
                        <router-link v-if="showEditMeeting(item.statusId)" class="ms-2" :to="{ name: 'addMeeting', params: { id: item.id } }">
                            <v-icon color="primary"> mdi-pencil</v-icon>
                        </router-link>
                    </template>
                </GridWithServerPaging>
            </v-card-text>

            <CommonDialog v-if="attendeesDialog" v-model="attendeesDialog" :fullscreen="false" width="40%" :title="$t('Attendees')" show-action-toolbar>
                <AttendeesList :meeting-id="selectedMeetingId" />
                <template v-slot:actions>
                    <v-btn color="error" class="rounded-lg" @click="attendeesDialog = false">
                        {{ $t('Cancel') }}
                    </v-btn>
                </template>
            </CommonDialog>

            <CommonDialog v-if="agendasDialog" v-model="agendasDialog" :fullscreen="true" :title="$t('Agenda')" show-action-toolbar>
                <MeetingAgendaList :meeting-id="selectedMeetingId" />
                <template v-slot:actions>
                    <v-btn color="error" class="rounded-lg" @click="agendasDialog = false">
                        {{ $t('Cancel') }}
                    </v-btn>
                </template>
            </CommonDialog>

            <v-dialog v-if="meetingDialogueOpened" v-model="meetingDialogueOpened" fullscreen hide-overlay persistent>
                <MeetingDashboard :meeting="selectedMeeting" :view-mode="meetingViewMode" @close="meetingDialogueOpened = false" />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService.js'
    import CommonDialog from '@/components/controls/CommonDialog'
    import AttendeesList from '@/components/app/meeting/MeetingAttendeesList.vue'
    import MeetingAgendaList from '@/components/app/meeting/MeetingAgendaList.vue'
    import DatePicker from '@/components/controls/DatePicker.vue'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'
    import LookupsService from '@/services/LookupsService.js'
    import { MeetingStatusEnum } from '@/helpers/constants/enumerations'
    import MeetingDashboard from '@/components/app/meeting/MeetingDashboard.vue'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'


    export default {
        components: {
            CommonDialog, AttendeesList, MeetingAgendaList, DatePicker, MeetingDashboard, GridWithServerPaging
        },
        directives: {
        },
        filters: {
        },
        props: {
        },
        data() {
            return {
                meetingDialogueOpened: false,
                selectedMeeting: null,
                meetingViewMode: true,
                selectedMeetingId: null,
                loading: false,
                search: '',
                attendeesDialog: false,
                agendasDialog: false,
                meetings: [],
                statusItems: [],
                committeeItems: [],
                selectedStatus: null,
                headers: [
                    { text: this.$t('Id'), value: 'id', width: '7%' },
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber', width: '10%' },
                    { text: this.$t('MeetingDate'), value: 'date', width: '10%' },
                    { text: this.$t('Subject'), value: 'title', sortable: false },
                    { text: this.$t('StartTime'), value: 'startTime' },
                    { text: this.$t('EndTime'), value: 'endTime' },
                    { text: this.$t('MeetingRoom'), value: 'location', sortable: false },
                    { text: this.$t('Progress'), value: 'statusId', sortable: false },
                    { text: this.$t('Status'), value: 'statusName' },
                    { text: this.$t('Actions'), value: 'preview', sortable: false, width: '11%' },
                ],
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right'
                },
                searchModel: {
                    statusId: null,
                    committeeId: null,
                    meetingId: null,
                    fromDate: null,
                    toDate: null,
                    location: null,
                    title: null,
                    onlyMyMeetings: true,
                },
                options: {
                    page: 1, itemsPerPage: 10
                },
                totalCount: null
            }
        },
        computed: {
            isAdmin() {
                return false
            },
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.loading = true
            let promises = [
                LookupsService.listMeetingStatuses(),
                CouncilCommitteesService.listUserCommittees(),
                MeetingsService.searchUserMeetings(this.searchModel, this.options.page, this.options.itemsPerPage)
            ]

            Promise.all(promises)
                .then(
                    ([
                        statuses,
                        comittees,
                        meetings
                    ]) => {
                        this.statusItems = statuses.data
                        this.committeeItems = comittees.data
                        this.meetings = meetings.data.data
                        this.totalCount = meetings.data.total
                    },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

        },
        mounted() {
        },

        methods: {
            listUserMeetings(options) {
                this.loading = true
                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                MeetingsService.searchUserMeetings(this.searchModel,page,pageSize).then((res) => {
                    if (res.data) {
                        this.meetings = res.data.data
                        this.totalCount = res.data.total

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            reset() {
                this.searchModel.statusId = null
                this.searchModel.committeeId = null
                this.searchModel.meetingId = null
                this.searchModel.fromDate = null
                this.searchModel.toDate = null
                this.searchModel.location = null
                this.searchModel.title = null
                this.searchModel.onlyMyMeetings = true
            },
            getMeetingProgress(status) {
                let count = Object.keys(MeetingStatusEnum).length
                let progress = (100 / count * status)
                return Math.ceil(progress) + '%'
            },

            showEditMeeting(status) {
                return status <= MeetingStatusEnum.Started
            },
            viewMeeting(meeting) {
                this.selectedMeeting = meeting
                this.meetingDialogueOpened = true

            }
        },
    }
</script>

<style>
</style>