<template>
    <v-app>
        <template v-if="isAppReady">
            <!-- if user is authenticated -->
            <template v-if="isLoggedIn">
                <Header @sidebarToggled="toggleDrawer" />

                <Sidebar ref="sideBar" />
              
                <v-main>
                      <v-divider class="custom-separator" />
                    <v-container fluid>
                        <router-view />
                    </v-container>
                </v-main>
            </template>
            <!-- if user is not authenticated -->
            <template v-else>
                <v-card class="elevation-0" min-height="98vh">
                    <router-view />
                </v-card>
            </template>
        </template>

        <!-- if translation is not yet loaded -->
        <template v-else>
            <SiteOverlay />
</template>

        <Notification ref="notification" />
        <ConfirmDialog ref="confirm" />
    </v-app>
</template>

<script>
    import '@/assets/css/site.css'
    import { mapGetters } from 'vuex'
    import { isReady } from '@/plugins/i18n/index'

    import Header from '@/components/layout/Header.vue'
    import Sidebar from '@/components/layout/Sidebar.vue'
    import SiteOverlay from '@/components/controls/SiteOverlay.vue'
    import Notification from '@/components/controls/Notification.vue'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'

    export default {
        name: 'App',

        components: {
            Header,
            Sidebar,
            SiteOverlay,
            Notification,
            ConfirmDialog
        },

        computed: {
            ...mapGetters('user', ['isAuthenticated']),

            isLoggedIn() {
                return this.isAuthenticated && this.$route.name != 'login'
            },

            isAppReady() {
                return isReady()
            },


        },
        mounted() {
            this.$root.$notification = this.$refs.notification.open
            this.$root.$confirm = this.$refs.confirm.open
            if (window.top !== window.self) {
                // Break out of the iframe if embedded
                window.top.location = window.location
            }
        },
        methods: {
            toggleDrawer() {
                let status = this.$refs.sideBar.mini
                this.$refs.sideBar.mini = !status
            }
        },
    }
</script>
