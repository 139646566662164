<template>
    <v-form ref="attendeesForm" v-model="valid">
        <v-simple-table class="attendees-table" dense>
            <tr v-if="!viewMode">
                <td class="col-md-3">
                    <h4 class="primary--text">{{ $t('SelectUser') }}</h4>
                    <UserSelect v-model="attendee.userId" hide-details class="mt-2" :outlined="true" :rules="nameRules" :label="$t('Attendees')" />
                </td>
                <td class="col-md-3">
                    <v-text-field v-model="attendee.jobTitle" :rules="cleanRequiredStringRules" hide-details outlined :label="$t('JobTitle')" />
                </td>
                <td class="col-md-2">
                    <v-checkbox v-model="attendee.needsApproval" class="mb-4" :label="$t('NeedsApproval')" hide-details />
                </td>
                <td class="col-md-3 text-start">
                    <v-btn :elevation="0" fab dark x-small :disabled="loading" color="secondary" @click="addAttendee">
                        <v-icon dark small>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </td>
            </tr>
            <tbody>
                <tr v-for="(att, index) in attendees" :key="meetingId+'-'+att.userId">
                    <td class="col-md-3">
                        <v-text-field v-model="att.name" disabled hide-details outlined dense :label="$t('Name')" />
                    </td>
                    <td class="col-md-3">
                        <v-text-field v-model="att.jobTitle" :rules="cleanStringRules"  @blur="onEndTyping(index)" :label="$t('JobTitle')" :disabled="viewMode" hide-details outlined dense />
                    </td>
                    <td class="col-md-2">
                        <v-checkbox v-model="att.needsApproval"
                                    :disabled="viewMode"
                                    hide-details class="mb-4"
                                    :label="$t('NeedsApproval')" 
                                    @change="checkboxUpdated($event, index)" />
                    </td>
                    <td class="col-md-3 text-start">
                        <v-btn v-if="!viewMode" class="mb-2" :elevation="0" fab dark x-small color="error" @click="deleteAttendee(index)">
                            <v-icon dark small>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </v-form>
</template>

<script>
    import UserSelect from '@/components/controls/UserSelect'
    import MeetingsService from '@/services/MeetingsService.js'

    export default {
        components: {
            UserSelect
        },
        props: {
            viewMode: {
                type: Boolean,
            },
            meetingId: {
                type: Number,
                default: 0

            },
            value: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                valid: true,
                loading: false,
                id: null,
                attendees: [],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                cleanStringRules: [this.$rules.onlyAlphanumericRule],
                nameRules: [v => !!v || this.$t('RequiredField')],
                attendee: {
                    userId: null,
                    needsApproval: false,
                    jobTitle: null,
                    name: ''
                }
            }
        },

        watch: {
            attendees: {
                handler: function () {
                    this.$emit('input', this.attendees)
                },
            },
            value: {
                handler: function () {
                    this.attendees = this.value
                }
            }
        },
        created() {
            this.attendees = this.value
        },
        methods: {
            checkboxUpdated(newValue,index){

                this.updateAttendee(index)  
            },
            onEndTyping(index){
                this.updateAttendee(index)  
            },
            addAttendee() {
                if (!this.$refs.attendeesForm.validate()) return

                this.loading = true
                MeetingsService.addMeetingAttendee(this.meetingId, this.attendee).then((res) => {
                    if (res.data) {
                        this.attendees = res.data
                        this.attendee.userId=null
                        this.attendee.needsApproval=false
                        this.attendee.jobTitle=null
                    }
                    else {
                        this.$root.$notification(this.$t('UserExistInMeeting'), 'info')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('UserExistInMeeting'), 'info')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteAttendee(index) {
                this.loading = true
                MeetingsService.removeMeetingAttendee(this.meetingId, this.attendees[index].userId).then((res) => {
                    if (res.data) {
                        this.attendees = res.data
                    }
                    else {
                        this.$root.$notification(this.$t('UpdateFailed'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            updateAttendee(index) {
                let formData ={
                    'userId':this.attendees[index].userId,
                    'jobTitle':this.attendees[index].jobTitle,
                    'needsApproval':this.attendees[index].needsApproval,
                    'name':'',

                }
                MeetingsService.updateMeetingAttendee(this.meetingId,formData).then((res) => {
                    if (res.data) {
                        this.attendees = res.data
                    }
                    else {
                       // this.$root.$notification(this.$t('UpdateFailed'), 'error')
                    }
                }).catch(() => {
                    //this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.btnLoading = false
                })
            }
        },
    }
</script>

<style>
    .attendees-table th {
        background: #f7f7f7
    }
</style>