<template>
    <div>
        <v-data-table v-if="votingTypesLoaded" :headers="headers" hide-default-footer :items="agendas" :items-per-page="20" :expanded.sync="expanded" :single-expand="singleExpand" show-expand item-key="id">
            <template v-slot:top>
                <v-btn v-if="!viewMode" class="ma-4 float-end" fab small color="primary" @click="openAddAgenda">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
            <template v-if="!viewMode" v-slot:[`item.options`]="{ item, index }">
                <v-icon color="primary" class="ms-3" @click="openEditAgenda(item,index)">mdi-pencil</v-icon>
                <v-icon color="error" class="ms-3" @click="deleteAgenda(index)">mdi-trash-can-outline</v-icon>
            </template>
            <template v-slot:[`item.voting`]="{ item }">
                {{ getVoting(item.votingTypeId) }}
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">
                    <v-card class="ma-4" tile>
                        <v-card-text>
                            <v-row v-for="(topicItem, topicIndex) in item.agendaTopics" :key="'topic'+ topicIndex">
                                <v-col cols="12" md="10">
                                    <v-icon>mdi-arrow-left-bold</v-icon>
                                    {{ topicItem.text }}
                                </v-col>
                            </v-row>
                            <v-row v-if="item.agendaTopics.length == 0">
                                <v-col cols="12" md="12">
                                    {{ $t('NoItemsToShow') }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>


        <v-dialog v-if="agendaDialog" v-model="agendaDialog" persistent max-width="40%">
            <v-card tile :loading="loading">
                <v-toolbar dense dark color="secondary" class="flex-grow-0">
                    <v-toolbar-title class="ms-2">
                        {{ title }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn icon dark @click="agendaDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form ref="agendaForm" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="12">
                                    <v-text-field v-model="agenda.title" outlined :rules="cleanRequiredStringRules" :label="$t('Subject')" required />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                    <v-text-field v-model="agenda.duration" outlined :rules="nameRules" type="number" :label="$t('Duration')" persistent-hint :hint="$t('InMinutes')" />
                                </v-col>
                                <v-col cols="12" sm="6" md="12">
                                    <v-select v-model="agenda.voting" outlined :rules="nameRules" :items="votingTypes" :label="$t('Voting')" item-text="name" item-value="id" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="12">
                                    <v-row v-if="!viewMode" class="my-2">
                                        <v-col cols="11">

                                            <v-form ref="topicForm" v-model="valid" lazy-validation>
                                                <v-text-field v-model="topic" hide-details outlined :label="$t('Topic')" :rules="cleanRequiredStringRules" />
                                            </v-form>
                                        </v-col>

                                        <v-col cols="1" class="text-start">
                                            <v-btn class="mt-1" :elevation="0" fab dark x-small color="primary" @click="addTopicRow">
                                                <v-icon dark small>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row v-for="(item, index) in agenda.agendaTopics" :key="index">
                                        <v-col cols="11">
                                            <v-text-field v-model="item.text" disabled hide-details outlined />
                                        </v-col>

                                        <v-col cols="1" class="text-start">
                                            <v-icon color="error" class="mt-4" @click="deleteTopicRow(index)">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions style="background:#e6e5e4">
                    <v-spacer />
                    <v-btn color="primary" @click="save">
                        {{ $t('Save') }}
                        <v-icon class="ms-2">mdi-floppy</v-icon>
                    </v-btn>
                    <v-btn color="error" @click="agendaDialog = false">
                        {{ $t('Cancel') }}
                        <v-icon class="ms-2">mdi-close</v-icon>
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import LookupsService from '@/services/LookupsService.js'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'
    import { mapGetters } from 'vuex'
import MeetingsService from '../../../services/MeetingsService'
    export default {
        props: {
            committeeId: {
                type: Number,
                default: null
            },
            meetingId: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
            },
            value: {
                type: Array,
                default: ()=>[]
            },
        },
        data() {
            return {
                requireRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                valid: true,
                loading: false,
                singleExpand: true,
                expanded: [],
                topicDialog: false,
                agendaDialog: false,
                editId: null,
                title: this.$t('Add'),
                agendas: [],
                topics: [],
                headers: [

                    { text: '#', value: 'id' },
                    { text: this.$t('Subject'), value: 'title' },
                    { text: this.$t('Duration'), value: 'duration' },
                    { text: this.$t('Voting'), value: 'voting' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],

                votingTypes: [],
                votingTypesLoaded: false,
                duties: [],
                agenda: {
                    duty: null,
                    committeeDutyId: null,
                    voting: null,
                    votingTypeId: null,
                    duration: null,
                    title: null,
                    agendaTopics: []
                },
                topic: '',
                selectedAgenda: null
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),
            isArabicLanguage() {
                return this.language == 'ar'
            },

        },
        watch: {

            agendas: {
                handler: function () {
                    this.$emit('input', this.agendas)
                },

            },
            value: {
                handler: function () {
                    this.agendas = this.value
                }
            }
        },

        created() {
            this.loadVotingTypes()
            this.agendas = this.value || []
        },


        methods: {
            getVoting(id) {

                if (this.votingTypes) return this.votingTypes.find(x => x.id == Number(id))?.name

                return ''
            },
            openAddAgenda() {
                this.title = this.$t('Add')
                this.editId = null
                this.resetFormInputs()
                this.agendaDialog = true
            },
            openEditAgenda(agenda, index) {
                this.resetFormInputs()
                this.title = this.$t('Edit')
                this.editId = agenda.id
                this.setFormInputs(agenda)
                this.agendaDialog = true

            },
            resetFormInputs() {
                this.agenda.duty = null
                this.agenda.committeeDutyId = null
                this.agenda.voting = null
                this.agenda.votingTypeId = null
                this.agenda.duration = null
                this.agenda.title = null
                this.agenda.agendaTopics = []

            },
            setFormInputs(selectedAgenda) {
                let dutyTitle = selectedAgenda.duty ? selectedAgenda.duty.title : null
                if (this.dutyTitle) {
                    this.agenda.duty = { title: dutyTitle }
                }
                this.agenda.id = selectedAgenda.id
                this.agenda.committeeDutyId = selectedAgenda.duty ? selectedAgenda.duty.id : null
                this.agenda.voting = parseInt(selectedAgenda.votingTypeId)
                this.agenda.duration = selectedAgenda.duration
                this.agenda.title = selectedAgenda.title
                this.agenda.agendaTopics = [...selectedAgenda.agendaTopics]

            },
            save() {
                if (this.$refs.agendaForm.validate()) {
                    let committeeDutyId = this.agenda.duty ? this.agenda.duty.id : null
                    if (this.editId) {
                        let editITem = {
                            id: this.editId,meetingId: this.meetingId, duty: '', committeeDutyId: committeeDutyId, voting: '', votingTypeId: this.agenda.voting,
                            duration: this.agenda.duration, title: this.agenda.title, agendaTopics: [...this.agenda.agendaTopics]
                        }
                        this.editAgenda(editITem)

                    } else {


                        let newItem={id:0,
                            meetingId: this.meetingId, duty: '', committeeDutyId: committeeDutyId, voting: '', votingTypeId: this.agenda.voting,
                            duration: this.agenda.duration, title: this.agenda.title, agendaTopics: [...this.agenda.agendaTopics]
                        }
                        this.addAgenda(newItem)
                    }
                    this.resetFormInputs()
                    this.$refs.agendaForm.resetValidation()
                    this.agendaDialog = false
                }
            },

            addAgenda(newItem) {

                this.loading = true
                MeetingsService.addMeetingAgenda(this.meetingId, newItem).then((res) => {
                    if (res.data) {
                        this.agendas = res.data
                    }
                    else {
                        this.$root.$notification(this.$t('UpdateFailed'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            editAgenda(editItem) {
                this.loading = true
                MeetingsService.updateMeetingAgenda(this.meetingId, editItem).then((res) => {
                    if (res.data) {
                        this.agendas = res.data
                    }
                    else {
                        this.$root.$notification(this.$t('UpdateFailed'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteAgenda(index) {
                this.loading = true
                MeetingsService.removeMeetingAgenda(this.meetingId, this.agendas[index].id).then((res) => {
                    if (res.data) {
                        this.agendas = res.data
                    }
                    else {
                        this.$root.$notification(this.$t('UpdateFailed'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            deleteTopic(agenda, index) {
                agenda.agendaTopics.splice(index, 1)
            },

            loadVotingTypes() {
                LookupsService.listVotingTypes().then((res) => {
                    if (res.data) {
                        this.votingTypes = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.votingTypesLoaded = true
                    this.loading = false
                })
            },

            listCommitteeDuties() {
                CouncilCommitteesService.listCommitteeDuties(this.committeeId).then((res) => {
                    if (res.data) {
                        this.duties = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            addTopicRow() {
                if (this.$refs.topicForm.validate()) {
                    this.agenda.agendaTopics.push({ text: this.topic })
                    this.topic = null
                }
            },
            deleteTopicRow(index) {
                this.agenda.agendaTopics.splice(index, 1)
            }
        },
    }
</script>

<style>
</style>