<template>
    <v-card class="elevation-0 pa-0" height="100%">
        <v-card-text class="text-center">
            <h3 class="teal-color text-center mb-4">{{ title }}</h3>
            <apexchart type="bar" :height="height" :options="barOptions" :series="series" />
        </v-card-text>
    </v-card>
</template>

<script>
    import apexchart from 'vue-apexcharts'

    export default {

        components: {
            apexchart
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 250
            },
            //series: {
            //    type: Array,
            //    default: () => []
            //},
            labels: {
                type: Array,
                default: () => []
            },
            horizontal: {
                type: Boolean,
                default: false
            },
            showBottomLabels: {
                type: Boolean,
                default: true
            },
            isArabic: {
                type: Boolean,
                default: false
            },
            distributed: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                series: [{
                    name: 'Net Profit',
                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                }, {
                    name: 'Revenue',
                    data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                }],
                barOptions: {
                    chart: {
                        type: 'bar',
                        fontFamily: 'Tajawal',
                        toolbar: {
                            show: true,
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: this.horizontal,
                            distributed: this.distributed,
                            columnWidth: '15%',
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        position: 'top',
                        style: {
                            colors: ['#fff']
                        },
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        show: false,
                        width: 2,
                    },

                    xaxis: {
                        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                        labels: {
                            show: this.showBottomLabels,
                            rotateAlways: false,
                            maxHeight: 100,
                            style: {
                                fontSize: '10px',
                                fontFamily: 'Tajawal',

                            }
                        }
                    },
                    grid: {
                        show: false, // Disable the grid lines
                    },
                    colors: ['#52dbce','#4fbab0',  '#b71c1c', '#1565c0', '#000'],
                    fill: {
                        opacity: 1
                    },
                },

            }
        }
    }
</script>

<style>
    .apexcharts-legend-text {
        padding: 0 5px !important
    }
</style>