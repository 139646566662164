<template>
    <v-menu v-model="menupicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="date"
                          :label="label"
                          :rules="rules"
                          color="primary"
                          :dense="dense"
                          :outlined="outlined"
                          :disabled="disabled"
                          v-bind="attrs"
                          :clearable="clearable"
                          :hide-details="hideDetails"
                          readonly
                          v-on="on"
                          @click:clear="handleClear" />
        </template>
        <v-date-picker v-model="date" :name="name" :max="maxDate" :min="minDate" @input="handleInput" />
    </v-menu>
</template>
<script>
    export default {

        props: {
            label: {
                type: String,
                default: null
            },

            value: {
                type: String,
                default: null
            },

            name: {
                type: String,
                default: null
            },

            rules: {
                type: Array,
                default: () => []
            },

            outlined: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },

            dense: {
                type: Boolean,
                default: false
            },

            clearable: {
                type: Boolean,
                default: false
            },

            maxDate: {
                type: String,
                default: null
            },

            minDate: {
                type: String,
                default: null
            },
            hideDetails: {
                type: Boolean,
                default: false
            },

        },

        data() {
            return {
                menupicker: false,
                date: null
            }
        },

        watch: {
            value(value) {
                this.date = value != null ? value.split('T')[0] : null
            }
        },

        created() {
            //remove time since datepicker is only date without time
            this.date = this.value != null ? this.value.split('T')[0] : null
        },

        methods: {
            handleInput() {
                this.menupicker = false
                this.$emit('input', this.date)
            },

            handleClear() {
                this.$emit('input', null)
            }
        }
    }
</script>