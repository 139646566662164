<template>
    <div>
        <v-navigation-drawer permanent app :right="isArabicLanguage ? true : false" width="17%" class="side-nav">
            <v-row class="fill-height"
                   no-gutters>
                <v-col cols="2">
                    <v-navigation-drawer color="primary"
                                         mini-variant
                                         mini-variant-width="60"
                                         permanent>
                        <v-list-item class="px-2 pb-4">
                            <img src="../../assets/images/Ilogo.png" alt="" height="40" class="mt-4">
                        </v-list-item>


                        <v-list dense dark class="pt-0">
                            <v-list-item v-for="item in menus" :key="item.id" style="height:58px;" class="mb-0">
                                <v-icon size="28">{{ item.icon }}</v-icon>
                            </v-list-item>
                        </v-list>
                    </v-navigation-drawer>
                </v-col>
                <v-col cols="10">
                    <v-list class="grow">
                        <v-list-item class="px-2 pb-0">
                            <v-card-title> {{ $t('ApplicationName')}}</v-card-title>
                        </v-list-item>
                        <template v-for="link in menus">
                            <v-list-item style="height:58px;" :key="link.id" v-if="link.route" :to="{ name: link.route }" link>
                                <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
                            </v-list-item>

                            <v-list-group v-if="!link.route" :key="link.id" no-action color="secondary" active-class="secondary--text">
                                <template v-slot:activator>

                                    <v-list-item-title v-if="link.route">
                                        <b class="text-decoration-none" :to="{ name: link.route }">{{ $t(item.title) }}</b>
                                    </v-list-item-title>
                                    <v-list-item-title v-else>
                                        {{ $t(link.title) }}
                                    </v-list-item-title>
                                </template>

                                <v-list-item v-for="child in link.children" :key="child.id" :to="{ name: child.route }" class="ps-8 primary--text">
                                    <v-list-item-subtitle>
                                        {{ $t(child.title) }}
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-list-group>
                        </template>
                    </v-list>

                    <v-speed-dial v-model="fab" class="ms-16"
                                  :top="top"
                                  absolute
                                  :bottom="bottom"
                                  :right="right"
                                  :left="left"
                                  :direction="direction"
                                  :open-on-hover="hover"
                                  :transition="transition">
                        <template v-slot:activator>
                            <v-btn v-model="fab"
                                   color="primary"
                                   dark
                                   fab>
                                <v-icon v-if="fab">
                                    mdi-close
                                </v-icon>
                                <v-icon v-else>
                                    mdi-comment-processing
                                </v-icon>
                            </v-btn>
                        </template>

                    </v-speed-dial>

                </v-col>
            </v-row>

        </v-navigation-drawer>
        <!--<ChatBox></ChatBox>-->
    </div>
    
</template>
<script>
    import { mapGetters } from 'vuex'
    import getMenus from '@/plugins/router/menus/menus.js'
    import UsersService from '@/services/UsersService.js'
    import ChatsService from '@/services/ChatsService'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    //import ChatBox from '@/components/app/Chat/ChatBox'

    export default {
        components: {
            //ChatBox
        },
        data() {
            return {
                chatMessagesCount: 0,
                drawer: true,
                selectedItem: 1,
                mini: true,
                menus: [],
                direction: 'top',
                fab: false,
                fling: false,
                hover: false,
                tabs: null,
                top: false,
                right: true,
                bottom: true,
                left: false,
                transition: 'slide-y-reverse-transition',
            }
        },

        computed: {
            ...mapGetters('user', ['language']),

            isArabicLanguage() {
                return this.language == 'ar'
            }
        },

        mounted() {
            this.getChatMessagesCount()

            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent('ChatMessagesCountChanges', this.getChatMessagesCount)
            }).catch((err) => {
            })
            
            UsersService.listCurrentUserPermissions().then((res) => {
                if (res.data) {
                    this.filterMenu(res.data)
                }
            }).catch(() => {
                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
            }).finally(() => {
                this.loading = false
            })
        },

        methods: {
            filterMenu(menuItems) {
                let menuList = getMenus()
                let rootElements = menuList.filter(x => x.children == null)
                let nodeElements = menuList.filter(x => x.children != null)

                for (var i = 0; i < menuItems.length; i++) {
                    let currentItem = menuItems[i]
                    if (currentItem.hasAccess) {
                        let menuItem = rootElements.filter(r => r.id == currentItem.name)[0]
                        if (menuItem) {
                            this.menus.push(menuItem)
                        }
                    }
                }

                for (var j = 0; j < nodeElements.length; j++) {
                    let nodeElement = nodeElements[j]
                    let childsArray = []
                    if (nodeElement) {
                        for (var k = 0; k < nodeElement.children.length; k++) {
                            let childElement = nodeElement.children[k]
                            if (menuItems.filter(x => x.name == childElement.id).length > 0) {
                                childsArray.push(childElement)
                            }
                        }
                        if (childsArray.length > 0) {
                            nodeElement.children = childsArray
                            this.menus.push(nodeElement)
                        }
                    }
                }
            },
            getChatMessagesCount() {
                ChatsService.getUnreadChatMessages().then((res) => {

                    this.chatMessagesCount = res.data

                }).catch(() => {
                    //this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                })
            },
        },
        beforeDestroy() {
            unsubscribeFromEvent('ChatMessagesCountChanges')
        },
    }
</script>
<style>

    .v-speed-dial {
        position: absolute;
    }
</style>