<template>
    <CommonDialog :value="value" :fullscreen="false" width="800" :title="votingType ? $t('EditVotingType') : $t('AddVotingType')" show-action-toolbar @input="$emit('input', false)">
        <v-card class="elevation-0">
            <v-form ref="typeForm" v-model="valid">
                <v-row class="ma-3">
                    <v-col cols="6">
                        <v-text-field v-model="voteTypeModel.nameAr" outlined :label="$t('NameAr')" :rules="requiredRules" required />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteTypeModel.nameEn" outlined :label="$t('NameEn')" :rules="requiredRules" required />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteTypeModel.displayOrder" outlined type="number" :label="$t('DisplayOrder')" :rules="requiredRules" required />
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-model="voteTypeModel.active" outlined :label="$t('Active')" />
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <template v-slot:actions>
            <v-btn v-show="!votingType" color="primary" @click="AddVotingType">
                {{ $t('Add') }}
            </v-btn>
            <v-btn v-show="votingType" color="secondary" @click="EditVotingType">
                {{ $t('Edit') }}
            </v-btn>
            <v-btn color="error" @click="$emit('input', false)">
                {{ $t('Cancel') }}
            </v-btn>
        </template>

    </CommonDialog>
</template>

<script>
    import SettingsService from '@/services/SettingsService'
    import CommonDialog from '@/components/controls/CommonDialog'

    export default ({
        components: {
            CommonDialog
        },

        props: {
            votingType: {
                type: Object,
                default: null
            },
            value: {
                type: Boolean,
                default: false
            },
            edit: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                valid: true,
                editId: null,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                voteTypeModel: {
                    id: 0,
                    nameAr: null,
                    nameEn: null,
                    active: false,
                    displayOrder: null,

                },
                requiredRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },

        created() {
            if (this.votingType) {
                this.voteTypeModel.id = this.votingType.id
                this.voteTypeModel.nameAr = this.votingType.nameAr
                this.voteTypeModel.nameEn = this.votingType.nameEn
                this.voteTypeModel.active = this.votingType.active
                this.voteTypeModel.displayOrder = this.votingType.displayOrder
            }
        },
        methods: {
            AddVotingType() {
                if (this.$refs.typeForm.validate()) {
                    this.loading = true
                    SettingsService.addVotingType(this.voteTypeModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('AddedSuccess'), 'success')
                            this.update()
                        } else {
                            this.$root.$notification(this.$t('HEEEEE'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }

            },

            EditVotingType() {
                if (this.$refs.typeForm.validate()) {
                    this.loading = true
                    SettingsService.updateVotingType(this.voteTypeModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                            this.update()
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }
            },
        },
    })
</script>

<style>
</style>