<template>
    <div id="designer">
        <div id="canvas" class="jtk-demo-canvas canvas-wide flowchart-demo jtk-surface jtk-surface-nopan">
            <div v-for="(task, index) in nodes" :id="task.BlockId" :key="task.BlockId" :class="task.ClassName">
                <div v-if="task.TypeId === taskActivity">
                    <div class="task-title text-end text-truncate">
                        {{ task.Title }}
                        <v-icon small color="error" class="ms-1">mdi-briefcase</v-icon>
                    </div>
                    <div class="task-description text-end text-truncate"><i>{{ task.Description }}</i></div>
                    <div class="actions-bar">
                        <v-btn fab dark x-small color="blue darken-2" class="me-2" @click="propsDialog = true; selectedTask = task">
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                        <v-btn fab dark x-small color="teal" class="me-2" @click="activityDetailsDialog = true; selectedTask = task">
                            <v-icon dark>
                                mdi-format-float-right
                            </v-icon>
                        </v-btn>
                        <v-btn fab dark x-small color="grey darken-2" class="me-2" @click="ownersDialog = true; selectedTask = task">
                            <v-icon>
                                mdi-account-check
                            </v-icon>
                        </v-btn>
                        <v-btn fab dark x-small color="black" class="me-2" @click="permissionDialog = true; selectedTask = task">
                            <v-icon dark>
                                mdi-account-key
                            </v-icon>
                        </v-btn>
                        <v-btn fab dark x-small color="error" class="me-2" @click="removeNode(index, task.BlockId)">
                            <v-icon dark>
                                mdi-trash-can-outline
                            </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <v-speed-dial v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left" :direction="direction" :open-on-hover="hover" :transition="transition">
            <template v-slot:activator>
                <v-btn v-model="fab" color="blue darken-2" dark fab>
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-credit-card-plus
                    </v-icon>
                </v-btn>
            </template>
            <v-btn small fab dark color="teal" @click="addNewTask(taskActivity, taskClassType, 'task')">
                <v-icon>mdi-account-box</v-icon>
            </v-btn>
        </v-speed-dial>

        <CommonDialog v-if="activityDetailsDialog" v-model="activityDetailsDialog" :fullscreen="false" width="600" :title="selectedTask.Title">
            <activity-details :task="selectedTask" @close="activityDetailsDialog = false" @update="updateDetails" />
        </CommonDialog>

        <CommonDialog v-if="transitionDetailsDialog" v-model="transitionDetailsDialog" :fullscreen="false" width="600" :title="selectedConnection.Id">
            <transition-details :js-plumb-connection="jsPlumbConnection" :form-data="formData" :connection="selectedConnection" @close="transitionDetailsDialog = false" @update="updateTransition" />
        </CommonDialog>

        <CommonDialog v-if="ownersDialog" v-model="ownersDialog" height="300" :fullscreen="false" width="600" :title="$t('TaskOwner')">
            <activity-owners :task="selectedTask" @update="updateOwner" @close="ownersDialog = false" />
        </CommonDialog>

        <CommonDialog v-if="propsDialog" v-model="propsDialog" :fullscreen="false" width="40%" :title="$t('TaskProperties')">
            <ActivityProps :task="selectedTask" @close="propsDialog = false" @update="updateTaskProperties" />
        </CommonDialog>
        <CommonDialog v-if="permissionDialog" v-model="permissionDialog" :fullscreen="false" width="40%" :title="$t('Permissions')">
            <ActivityPermissions :task="selectedTask" @close="permissionDialog = false" @update="updateTaskRules" />
        </CommonDialog>
    </div>
</template>

<script>
    import '@/assets/css/jsplumbtoolkit-defaults.min.css'
    import '@/assets/css/jsplumbtoolkit.min.css'
    import '@/assets/css/flowchart.min.css'
    import { createGuid } from '@/helpers/common.js'
    import { ActivityTypesEnum, ActivityClassTypesEnum } from '@/helpers/constants/enumerations.js'

    import CommonDialog from '@/components/controls/CommonDialog'
    import ActivityDetails from '@/components/app/admin/case/ActivityDetails.vue'
    import TransitionDetails from '@/components/app/admin/case/TransitionDetails.vue'
    import ActivityOwners from '@/components/app/admin/case/ActivityOwners.vue'
    import ActivityProps from '@/components/app/admin/case/ActivityProperties.vue'
    import ActivityPermissions from '@/components/app/admin/case/ActivityPermissions.vue'

    export default {

        components: {
            ActivityDetails, CommonDialog, TransitionDetails, ActivityOwners, ActivityProps, ActivityPermissions
        },

        props: {
            formData: {
                type: Array,
                default: () => []
            },
        },

        data() {
            return {
                taskActivity: ActivityTypesEnum.TaskActivity,
                taskClassType: ActivityClassTypesEnum.TaskActivity,
                activityDetailsDialog: false,
                transitionDetailsDialog: false,
                permissionDialog: false,
                propsDialog: false,
                ownersDialog: false,
                fab: false,
                top: false,
                right: true,
                bottom: true,
                left: false,
                hover: false,
                direction: 'top',
                container: 'canvas',
                transition: 'slide-y-reverse-transition',
                nodes: [],
                connections: [],
                newNodes: [],
                properties: [],
                selectedItem: null,
                selectedTask: null,
                selectedConnection: null,
                jsPlumbConnection: null,
                instance: null,
                selectedType: 1,
                connectorPaintStyle: {
                    strokeWidth: 1,
                    stroke: 'green',
                    joinstyle: 'round'
                },
                connectorHoverStyle: {
                    strokeWidth: 1,
                    stroke: 'blue',
                    outlineWidth: 1,
                    fill: 'none'
                },
                endpointHoverStyle: {
                    fill: '#03968d',
                    strokeWidth: 0,
                    stroke: '#333'
                },
                sourceEndpoint: {
                    endpoint: 'Dot',
                    paintStyle: {
                        stroke: '#03968d',
                        fill: 'transparent',
                        radius: 7,
                        strokeWidth: 2
                    },
                    isSource: true,
                    connector: ['Flowchart', { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }],
                    connectorStyle: this.connectorPaintStyle,
                    hoverPaintStyle: this.endpointHoverStyle,
                    connectorHoverStyle: this.connectorHoverStyle,
                    dragOptions: {},
                    overlays: [
                        ['Label', {
                            location: [0.5, 1.5],
                            label: 'Drag',
                            cssClass: 'endpointSourceLabel',
                            visible: false
                        }]
                    ]
                },
                targetEndpoint: {
                    endpoint: 'Dot',
                    paintStyle: { stroke: 'black', fill: '', strokeWidth: 2, radius: 7 },
                    hoverPaintStyle: this.endpointHoverStyle,
                    maxConnections: -1,
                    dropOptions: { hoverClass: 'hover', activeClass: 'active' },
                    isTarget: true,
                    overlays: [
                        ['Label', { location: [0.5, -0.5], label: "<i class='fas fa-cog' style='color:#333' aria-hidden='true'></i>", cssClass: 'endpointTargetLabel', visible: false }]
                    ]
                },
                connectionOverlays: [
                    ['Arrow', {
                        location: 1,
                        visible: true,
                        width: 15,
                        cssClass: 'arrow',
                        length: 15,
                        id: 'ARROW',
                        events: {
                            click: function () {
                                // click on arrow
                            }
                        }
                    }],
                    ['Label', {
                        location: 0.45,
                        id: 'label',
                        cssClass: 'aLabel',
                        events: {
                            click: this.openConditionalModal
                        }
                    }]
                ],
            }
        },

        mounted() {
            if (!this.$route.query.id) {
                this.initiate()
            }
        },

        methods: {

            addTask(type, classTypeId, className, taskTitle) {
                let node = {
                    BlockId: 'Window' + (createGuid()), ClassName: `window jtk-node ${className}`,
                    TypeId: type,
                    ClassTypeId: classTypeId,
                    Title: taskTitle + ' ' + this.nodes.length,
                    Name: '',
                    Description: this.$t('Description'),
                    Index: this.nodes.length,
                    DueDate: '',
                    OwnerTypeId: null,
                    Owner: null,
                    ActivityProperties: [],
                    ActivityRule: {},
                    PositionX: 0,
                    PositionY: 0
                }
                this.newNodes.push(node)
                return node
            },

            addNewTask(type, classTypeId, className) {
                let vueInstance = this
                let instance = this.instance
                let node = vueInstance.addTask(type, classTypeId, className, this.$t('NewTask'))
                vueInstance.nodes.push(node)
                let sourceAnchors = ['RightMiddle', 'TopRight', 'BottomRight', 'LeftMiddle']
                let targetAnchors = ['TopCenter', 'BottomCenter', 'TopLeft', 'BottomLeft']
                vueInstance.$nextTick(function () {
                    instance.batch(function () {
                        vueInstance._addEndpoints(node.BlockId, sourceAnchors, targetAnchors, vueInstance.sourceEndpoint, vueInstance.targetEndpoint, instance)
                        instance.draggable(jsPlumb.getSelector('.window'), { grid: [20, 20] })
                        instance.bind('connection', function (connInfo, originalEvent) {
                            connInfo.connection.getOverlay('label').setLabel("<i class='v-icon notranslate v-icon--link mdi mdi-directions-fork theme--light'></i>")
                        })
                    })
                })
            },

            _addEndpoints(toId, sourceAnchors, targetAnchors, sourceEndpoint, targetEndpoint, instance) {
                for (var i = 0; i < sourceAnchors.length; i++) {
                    var sourceUUID = toId + sourceAnchors[i]
                    instance.addEndpoint(toId, sourceEndpoint, { anchor: sourceAnchors[i], uuid: sourceUUID })
                }
                for (var j = 0; j < targetAnchors.length; j++) {
                    var targetUUID = toId + targetAnchors[j]
                    instance.addEndpoint(toId, targetEndpoint, { anchor: targetAnchors[j], uuid: targetUUID })
                }
            },

            removeNode(index, id) {
                this.nodes.splice(index, 1)
                var parentnode = document.getElementById(id).parentNode
                jsPlumb.remove(jsPlumb.getSelector('#' + id))
                var targetEndpoints = this.instance.getConnections({ target: id })
                var sourceEndpoints = this.instance.getConnections({ source: id })
                this.instance.removeAllEndpoints(id)
                this.removeTargetSourceEndpointsFromArray(targetEndpoints, sourceEndpoints)
                this.removeFromNewNodes(id)

            },

            openConditionalModal(connection) {
                let conn = this.connections.filter(c => c.Id === connection.component.id)
                let connectionExists = conn.length > 0
                if (!connectionExists) {
                    let currentConnection = {
                        Id: connection.component.id, Complete: true, Condition: '', DestinationAnchorPosition: '', LabelName: '', OriginalId: '', PageSourceId: '', PageTargetId: '', SourceAnchorPosition: ''
                    }
                    this.connections.push(currentConnection)
                    this.selectedConnection = currentConnection
                }
                else {
                    this.selectedConnection = conn[0]
                }
                this.jsPlumbConnection = connection
                this.transitionDetailsDialog = true
            },

            removeTargetSourceEndpointsFromArray(targetEndpoints, sourceEndpoints) {
                for (var i = 0; i < targetEndpoints.length; i++) {
                    let targetPointId = targetEndpoints[i].id
                    this.connections = this.connections.filter(con => con.Id !== targetPointId)
                }
                for (var i = 0; i < sourceEndpoints.length; i++) {
                    let sourcePointId = sourceEndpoints[i].id
                    this.connections = this.connections.filter(con => con.Id !== sourcePointId)
                }
            },

            removeFromNewNodes(id) {
                let itemExist = this.newNodes.filter(node => node.BlockId === id)[0]
                if (itemExist)
                    this.newNodes = this.newNodes.filter(node => node.BlockId !== id)
            },

            initiate() {
                let vueInstance = this
                jsPlumb.ready(function () {
                    vueInstance.nodes.push(vueInstance.addTask(ActivityTypesEnum.StartActivity, ActivityClassTypesEnum.StartActivity, 'oval', 'Start'))
                    vueInstance.nodes.push(vueInstance.addTask(ActivityTypesEnum.EndActivity, ActivityClassTypesEnum.EndActivity, 'oval end', 'End'))

                    let instance = window.jsp = jsPlumb.getInstance({
                        DragOptions: { cursor: 'pointer', zIndex: 2000 },
                        ConnectionOverlays: vueInstance.connectionOverlays,
                        Container: vueInstance.container
                    })

                    vueInstance.instance = instance
                    vueInstance.$nextTick(function () {
                        instance.batch(function () {
                            vueInstance.nodes.map(function (node, key) {
                                let sourceAnchors = []
                                let targetAnchors = []
                                switch (node.TypeId) {
                                    case ActivityTypesEnum.StartActivity:
                                        sourceAnchors = ['TopCenter', 'RightMiddle', 'BottomCenter']
                                        targetAnchors = []
                                        break
                                    case ActivityTypesEnum.EndActivity:
                                        sourceAnchors = []
                                        targetAnchors = ['TopCenter', 'BottomCenter', 'RightMiddle', 'LeftMiddle']
                                        break
                                }
                                vueInstance._addEndpoints(node.BlockId, sourceAnchors, targetAnchors, vueInstance.sourceEndpoint, vueInstance.targetEndpoint, instance)
                                instance.draggable(jsPlumb.getSelector('.window'), { grid: [20, 20] })
                            })
                        })
                    })
                })
            },

            updateTransition(connection) {
                let conn = this.connections.filter(c => c.Id === connection.connectionId)
                if (conn) {
                    conn[0].Complete = connection.complete
                    conn[0].Condition = connection.condition
                    conn[0].LabelName = connection.uilabel
                    this.transitionDetailsDialog = false
                }

            },

            updateDetails(details) {
                this.selectedTask.Name = details.Name
                this.selectedTask.Title = details.Title
                this.selectedTask.Description = details.Description
                this.selectedTask.DueDate = details.DueDate
                this.activityDetailsDialog = false
            },

            updateOwner(ownerTypeId, ownerId) {
                this.selectedTask.OwnerTypeId = ownerTypeId
                this.selectedTask.Owner = ownerId
                this.ownersDialog = false
            },

            updateTaskProperties(props) {
                this.selectedTask.ActivityProperties = props
                this.propsDialog = false
            },

            updateTaskRules(rules) {
                this.selectedTask.ActivityRule = rules
                this.permissionDialog = false
            }
        },
    }

</script>


<style scoped>
    #designer .v-speed-dial {
        position: absolute;
        left: 95%;
        bottom: 5%
    }

    #designer {
        direction: ltr !important
    }

        #designer .v-btn--floating {
            position: relative;
        }

    #canvas {
        width: 100%;
        height: 100%;
        overflow: scroll !important;
        min-height: 77vh;
    }
</style>