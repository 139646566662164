<template>
    <CommonDialog :value="value" :fullscreen="false" width="800" :title="edit == true ? $t('Edit') : $t('Add')" show-action-toolbar @input="$emit('input', false)">
        <v-form ref="councilCommitteeForm" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-switch outlined v-model="councilCommittee.active" :label="$t('Active')" color="teal" hide-details />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field outlined v-model="councilCommittee.nameAr" :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field outlined v-model="councilCommittee.nameEn" :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field outlined v-model="councilCommittee.code" :label="$t('Code')" :rules="cleanRequiredStringRules" required />
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-autocomplete v-model="councilCommittee.typeId" :loading="loading" :rules="nameRules" item-text="name" item-value="id" :items="committeeTypes" :label="$t('Type')" outlined />
                    </v-col>
                    <v-col cols="12" md="6">
                        <DatePicker v-model="councilCommittee.startDate" :outlined="true" :label="$t('StartDate')" :rules="requiredDate" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <DatePicker v-model="councilCommittee.endDate" :outlined="true"  :label="$t('EndDate')" :rules="requiredDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="councilCommittee.description" :rules="cleanStringRules" :label="$t('Description')" hide-details outlined rows="3" />
                    </v-col>

                    <v-col cols="12">
                        <v-autocomplete v-if="showParentsOptions" v-model="councilCommittee.parentId" :loading="loading" outlined item-text="name" item-value="id" :items="committees" :label="$t('ParentCouncilOrCommittee')" />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <template v-slot:actions>
            <v-btn v-if="!edit" color="secondary" class="white--text" :disabled="posting" @click="save()">
                {{ $t("Add") }}
            </v-btn>
            <v-btn v-if="edit" color="secondary" class="white--text" :disabled="posting" @click="update()">
                {{ $t("Update") }}
            </v-btn>
            <v-btn color="error" class="white--text" :disabled="posting" @click="$emit('input', false);">
                {{ $t("Cancel") }}
            </v-btn>
        </template>
    </CommonDialog>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import LookupsService from '@/services/LookupsService.js'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'
    import DatePicker from '@/components/controls/DatePicker.vue'
    import { CommitteeType } from '@/helpers/constants/enumerations'
    export default {
        components: {
            CommonDialog, DatePicker
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
            edit: {
                type: Boolean,
                default: false
            },
            councilCommitteeId: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                valid: true,
                loading: false,
                posting: false,
                showParentsOptions: true,
                committeeTypes: [],
                committees: [],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.noHtmlTagsRule, this.$rules.onlyAlphanumericRule],
                cleanStringRules: [this.$rules.noHtmlTagsRule, this.$rules.onlyAlphanumericRule],
                requiredDate: [this.$rules.requiredRule],
                councilCommittee: {
                    id: null,
                    nameAr: '',
                    nameEn: '',
                    code: '',
                    description: '',
                    typeId: null,
                    parentId: null,
                    active: true,
                    startDate: null,
                    endDate: null,
                }
            }
        },
        watch: {
            'councilCommittee.typeId': function (newVal) {
                if (newVal == CommitteeType.Council) {
                    this.showParentsOptions = false
                    this.councilCommittee.parentId = null
                } else {
                    this.showParentsOptions = true
                }
            }
        },
        created() {
            this.listLookups()
            if (this.edit && this.councilCommitteeId) {
                this.loadCouncilCommittee()
            }
        },

        methods: {

            loadCouncilCommittee() {
                CouncilCommitteesService.getCouncilCommittee(this.councilCommitteeId).then((res) => {
                    if (res.data) {
                        this.councilCommittee = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            listLookups() {
                this.loading = true
                let promises = [
                    LookupsService.listCommitteeTypes(),
                    LookupsService.listCommittees()
                ]

                Promise.all(promises)
                    .then(
                        ([
                            committeeTypes,
                            committees
                        ]) => {
                            this.committeeTypes = committeeTypes.data
                            this.committees = committees.data
                        },
                    ).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },

            save() {
                if (this.$refs.councilCommitteeForm.validate()) {
                    CouncilCommitteesService.addCouncilCommittee(this.councilCommittee).then((res) => {
                        this.$refs.councilCommitteeForm.resetValidation()
                        this.resetCommitteeObj()
                        this.$emit('update', true)

                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },


            update() {
                if (this.$refs.councilCommitteeForm.validate()) {
                    CouncilCommitteesService.updateCouncilCommittee(this.councilCommitteeId, this.councilCommittee).then((res) => {
                        this.$refs.councilCommitteeForm.resetValidation()
                        this.$emit('update', true, this.$i18n.locale == 'ar' ? this.councilCommittee.nameAr : this.councilCommittee.nameEn)
                        this.resetCommitteeObj()
                        this.$emit('input', false)
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            resetCommitteeObj() {
                this.councilCommittee.id = null
                this.councilCommittee.nameAr = ''
                this.councilCommittee.nameEn = ''
                this.councilCommittee.code = ''
                this.councilCommittee.description = ''
                this.councilCommittee.typeId = null
                this.councilCommittee.parentId = null
                this.councilCommittee.active = true
            }
        },
    }
</script>

<style>
</style>