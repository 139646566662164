<template>
    <div>
        <v-card class="mb-3 rounded-lg">
            <v-card-text class="mb-2">
                <v-row>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.meetingReferenceNo" outlined :label="$t('ReferenceNumber')" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.title" outlined :label="$t('Title')" />
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-btn tile :disabled="loading"
                               class="me-1 pt-0 white--text rounded-xl"
                               color="primary"
                               type="submit"
                               @click="listRecommendations()">
                            {{ $t('Search') }}
                            <v-icon class="ms-2">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn tile :disabled="loading"
                               class="ms-2 white--text rounded-xl"
                               color="grey"
                               @click="reset()">
                            {{ $t('Reset') }}
                            <v-icon class="ms-2">mdi-refresh</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <GridWithServerPaging :headers="headers" :total="totalCount" :items="comittees" :items-per-page="10" @onChange="loadComittees">
                    <template v-slot:[`item.startDate`]="{ item }">
                        {{ item.startDate | formatDateTimeGregorian }}
                    </template>
                    <template v-slot:[`item.endDate`]="{ item }">
                        {{ item.endDate | formatDateTimeGregorian }}
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.name | trim(60) }}
                    </template>
                    <template v-slot:[`item.isActive`]="{ item }">
                        <v-chip class="ma-2" small color="#d3f5f2" v-if="item.isActive">
                            <v-icon>
                                mdi-check
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" color="#fadadf" small v-else>
                            {{ item.endTime }}
                            <v-icon right small>
                                mdi-close
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.attendanceRate`]="{ item }">
                        <v-progress-circular :rotate="-90" :size="35" :width="3" :value="item.attendanceRate" color="primary">
                            <h6>{{ item.attendanceRate }}</h6>
                        </v-progress-circular>
                    </template>
                    <template v-slot:[`item.meetingsCount`]="{ item }">
                        <div class="text-center">
                            <v-chip class="ma-2" @click="showMeetingDetails(item)"
                                    color="#456191"
                                    label
                                    text-color="white">
                                <v-icon left >
                                    mdi-chart-bar
                                </v-icon>
                                {{ item.meetingsCount }}
                            </v-chip>
                        </div>
                    </template>
                </GridWithServerPaging>
            </v-card-text>
        </v-card>
        <v-dialog v-if="showMeetingCountDialogue" v-model="showMeetingCountDialogue" width="75%">
            <StatusesMeetingsCountChart :committee-id="selectedCommitteeId" :note="selectedNote" @close="showMeetingCountDialogue = false" />
        </v-dialog>
    </div>
</template>

<script>
    import ReportsService from '@/services/ReportsService.js'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'
    import StatusesMeetingsCountChart from '@/components/app/Reports/StatusesMeetingsCountChart.vue'

    export default {
        components: {
            GridWithServerPaging, StatusesMeetingsCountChart
        },
        data() {
            return {
                loading: false,
                comittees: [],
                selectedCommitteeId: null,
                showMeetingCountDialogue: false,
                headers: [
                    { text: this.$t('Id'), value: 'id', width: '6%' },
                    { text: this.$t('Name'), value: 'name', width: '15%' },
                    { text: this.$t('Active'), value: 'isActive' },
                    { text: this.$t('StartDate'), value: 'startDate', width: '15%' },
                    { text: this.$t('EndDate'), value: 'endDate', width: '15%' },
                    { text: this.$t('MeetingsCount'), value: 'meetingsCount', align: 'start', sortable: false },
                    { text: this.$t('SubCommitteesCount'), value: 'subCommitteesCount', align: 'start' },
                    { text: this.$t('MembersCount'), value: 'membersCount', align: 'start' },
                    { text: this.$t('RecommendationsCount'), value: 'recommendationsCount', align: 'start' },
                    { text: this.$t('AttendanceRate'), value: 'attendanceRate', align: 'start',  sortable: false },
                ],
                searchModel: {
                    meetingReferenceNo: null,
                    fromDate: null,
                    toDate: null,
                    title: null,
                },
                options: {
                    page: 1, itemsPerPage: 10
                },
                totalCount: null,
            }
        },
        computed: {
            headers2() {
                if (!this.comittees || this.comittees.length == 0) {
                    return this.staticHeaders
                }
                const dynamicHeaders = this.comittees[0].statusesMeetingsCount.map((status, index) => ({
                    text: status.name,
                    value: `statusesMeetingsCount[${index}].meetingsCount`,
                }))

                return [...this.staticHeaders, ...dynamicHeaders]
            },

        },
        watch: {
        },
        created() {

        },
        mounted() {
            this.loadComittees(this.options)
        },
        methods: {
            loadComittees(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                ReportsService.getComitteesSummary(page, pageSize).then((res) => {
                    this.comittees = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            showMeetingDetails(committee) {
                this.selectedCommitteeId = committee.id
                this.showMeetingCountDialogue = true
            }
        },
    }
</script>

<style>
</style>