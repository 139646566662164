<template>
    <v-card class="rounded-lg" :loading="loading">
        <v-card-title>
            <v-text-field v-model="search" outlined clearable append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details />
        </v-card-title>
        <v-card-text>
            <v-data-table :search="search" :headers="headers" :items="permissions" :items-per-page="10" :footer-props="footerProps">
                <template v-slot:[`item.isDefault`]="{ item }">
                    <v-icon v-if="item.isDefault" color="primary">
                        {{  'mdi-check'  }}
                    </v-icon>
                    <v-icon v-else color="error">
                        {{ 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <v-icon color="primary" class="ms-3" @click="editItem(item)"> mdi-pen</v-icon>
                </template>
            </v-data-table>
        </v-card-text>
        <v-form ref="permissionsForm" v-model="valid" lazy-validation>
            <CommonDialog v-model="dialog" :fullscreen="false" width="500" :title="title" show-action-toolbar>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-switch v-model="permission.isDefault" :label="$t('IsDefaultPermission')" color="teal darken-2" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="permission.order" outlined type="number" :label="$t('DisplayOrder')" :rules="requireRules" required />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined v-model="permission.description" :rules="cleanRequiredStringRules" :label="$t('Description')" />
                        </v-col>
                    </v-row>
                </v-container>

                <template v-slot:actions>
                    <v-btn color="primary" class="white--text" :disabled="loading" @click="update()">
                        {{ $t("Update") }}
                    </v-btn>
                    <v-btn color="error" class="white--text" :disabled="loading" @click="dialog = false; reset()">
                        {{ $t("Cancel") }}
                    </v-btn>
                </template>
            </CommonDialog>
        </v-form>
    </v-card>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import PermissionsService from '@/services/PermissionsService.js'

    export default {
        components: {
            CommonDialog
        },

        data() {
            return {
                title: '',
                search: '',
                valid: true,
                loading: false,
                dialog: false,
                selectedPermissionId: null,
                permissions: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Description'), value: 'description' },
                    { text: this.$t('Name'), value: 'name' },
                    { text: this.$t('DisplayOrder'), value: 'order' },
                    { text: this.$t('Type'), value: 'typeName' },
                    { text: this.$t('IsDefaultPermission'), value: 'isDefault' },
                    { text: this.$t('Options'), value: 'options', width: '8%' }
                ],
                requireRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right'
                },
                permission: {
                    description: '',
                    order: 0,
                    isDefault: false,
                }
            }
        },

        created() {
            this.loadPermissions()
        },

        methods: {
            loadPermissions() {
                this.loading = true
                PermissionsService.loadSystemPermissions().then((res) => {
                    if (res.data) {
                        this.permissions = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },


            update() {
                if (this.$refs.permissionsForm.validate()) {
                    PermissionsService.updateSystemPermission(this.selectedItemId, this.permission).then((res) => {
                        this.dialog = false
                        this.loadPermissions()
                        this.reset()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },



            reset() {
                this.permission.order = 0
                this.permission.description = ''
                this.permission.isDefault = false
                this.selectedItemId = null
                this.title = ''
            },

            editItem(item) {
                this.permission.isDefault = item.isDefault
                this.permission.order = item.order
                this.permission.description = item.description
                this.selectedItemId = item.id
                this.title = item.name
                this.dialog = true
            },
        },
    }
</script>

<style>
</style>