<template>
    <v-layout column>

        <div class="grey--text mb-4 text-center">
            {{ $t("Login") }}
        </div>
        <v-text-field v-model="username"
                      :label="$t('Username')"
                      autofocus color="primary"
                      autocomplete="false"
                      outlined
                      :rules="cleanStringRules"
                      prepend-icon="mdi-account"
                      @keydown.enter.prevent="login()" />

        <v-text-field v-model="password" color="primary" type="password"
                      :label="$t('Password')"
                      outlined
                      class="my-2"
                      :rules="cleanStringRules"
                      autocomplete="false"
                      prepend-icon="mdi-lock"
                      @keydown.enter.prevent="login()" />

        <v-btn class="white--text my-4"
               color="primary"
               block
               :loading="loginLoading"
               :disabled="disableLogin"
               @click="login">
            {{ $t("Login") }}
        </v-btn>

        <div v-if="errorLogin"
             class="mt-2 ms-2 me-2 error--text">
            {{errorMessage }}
        </div>
    </v-layout>

</template>
<script>
    import AuthService from '@/services/AuthService'
    import { getUserImage } from '@/helpers/common.js'
    import { createSignalRConnection, startSignalRConnection } from '@/plugins/hub/myHubPlugin.js'

    export default {
        data() {
            return {
                username: '',
                password: '',
                loginLoading: false,
                errorLogin: false,
                errorMessage:'',
                cleanStringRules:[this.$rules.requiredRule, this.$rules.noHtmlTagsRule]

            }
        },

        computed: {
            disableLogin() {
                return this.loginLoading || !this.username || !this.password
            }
        },

        methods: {

            login: function () {

                if (this.username && this.password) {
                    this.errorLogin = false
                    this.loginLoading = true

                    AuthService.login(this.username, this.password)
                        .then(async (res) => {
                            if (res.data.statusCode == 206) {
                                await this.$store.dispatch('user/setUser2FA', res.data.userInfo)
                                window.location.href = '/2FA'
                            } else if (res.data.statusCode == 423) {
                                this.errorLogin = true
                                this.errorMessage=this.$t('UserLocked')
                            }
                            else {
                                await this.$store.dispatch('user/setUserSession', res.data)

                                if (res.data.user.hasProfilePicture) {
                                    await this.$store.dispatch('user/setProfilePictureUrl', getUserImage(res.data.user.id))

                                }
                                await this.$store.dispatch('app/fetch_options')
                                createSignalRConnection()
                                await startSignalRConnection()
                                this.$router.push({ name: 'home' })
                            }
                        })
                        .catch(() => {
                            this.errorLogin = true
                            this.errorMessage=this.$t('InvalidLogin')
                        }).finally(() => {
                            this.loginLoading = false

                        })
                }
            },
        }
    }
</script>
<style>
    input:-webkit-autofill {
        background-color: #ffffff !important; /* Set your preferred background color */
        color: #000000 !important; /* Set your preferred text color */
      
        transition: background-color 5000s ease-in-out 0s;
    }
    input:-moz-autofill {
        background-color: #ffffff !important; /* Override Firefox's autofill background */
    }
    input:focus, textarea:focus, select:focus {
        outline: none; /* Removes the outline */
        box-shadow: none; /* Removes any shadow */
        border-color: #ccc; /* Optional: You can set your own border color */
    }
</style>