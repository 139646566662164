<template>
    <v-card :loading="loading" class="elevation-0">
        <v-data-table :headers="headers"
                      :items="attendees"
                      :items-per-page="10"
                      class="elevation-0">
            <template v-slot:[`item.needsApproval`]="{ item }">
                <v-icon v-if="item.needsApproval" color="teal">mdi-check-bold</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:[`item.attended`]="{ item }">
                <v-chip color="#d3f5f2" v-if="item.attended">
                    <v-icon>mdi-account-check-outline</v-icon>
                </v-chip>
                <v-chip v-else color="#fadadf">
                    <v-icon>mdi-account-remove-outline</v-icon>
                </v-chip>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
                <v-icon v-if="item.approved" color="teal">mdi-check-bold</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService.js'

    export default {
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('FullName'), value: 'fullName',sortable: false },
                    { text: this.$t('JobTitle'), value: 'jobTitle',sortable: false },
                    { text: this.$t('NeedsApproval'), value: 'needsApproval', align: 'center',sortable: false },
                    { text: this.$t('Approved'), value: 'approved', align: 'center',sortable: false },
                    { text: this.$t('Attended'), value: 'attended',sortable: false },
                ],
                loading: false,
                attendees: []
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.listAttendees(this.meetingId)
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            listAttendees(meetingId) {
                this.loading = true
                MeetingsService.listAttendees(meetingId).then(res => {
                    if (res.data) {
                        this.attendees = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
</script>

<style>
</style>