import { render, staticRenderFns } from "./FormBuilder.vue?vue&type=template&id=49db6dab&scoped=true"
import script from "./FormBuilder.vue?vue&type=script&lang=js"
export * from "./FormBuilder.vue?vue&type=script&lang=js"
import style0 from "./FormBuilder.vue?vue&type=style&index=0&id=49db6dab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49db6dab",
  null
  
)

export default component.exports