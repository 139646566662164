<template>
    <CommonDialog :value="value" :fullscreen="false" width="800" :title="$t('VotingTypeOptions')" show-action-toolbar @input="$emit('input', false)">
        <v-card class="elevation-0">
            <v-form ref="voteForm">
                <v-row class="ma-3">
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.nameAr" outlined :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.nameEn" outlined :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.displayOrder" outlined type="number" :label="$t('DisplayOrder')" :rules="requiredRules" required />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.weight" outlined type="number" :label="$t('Weight')" :rules="requiredRules" required />
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-model="voteOptionModel.active" outlined :label="$t('Active')" />
                    </v-col>
                </v-row>
            </v-form>
            <v-data-table :headers="headers"
                          item-key="id"
                          :items="voteOptions"
                          :items-per-page="5" hide-default-footer>
                <template v-slot:[`item.active`]="{ item }">
                    <v-icon :color="item.active ? 'primary' : 'error'">
                        {{ item.active ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="mx-2" color="primary"
                            :disabled="loading"
                            @click="EnableEdit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon color="error"
                            :disabled="loading"
                            @click="deleteVote(item.id)">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
        <template v-slot:actions>
            <v-btn v-show="!selectedOption" color="teal" dark @click="AddVotingOption">
                {{ $t('Add') }}
            </v-btn>
            <v-btn v-show="selectedOption" color="teal" dark @click="EditVotingOption">
                {{ $t('Edit') }}
            </v-btn>
            <v-btn v-show="selectedOption" color="error" @click="CancelEdieVoteOption">
                {{ $t('Cancel') }}
            </v-btn>
            <v-btn v-show="!selectedOption" color="error" @click="$emit('input', false)">
                {{ $t('Cancel') }}
            </v-btn>
        </template>
    </CommonDialog>
</template>

<script>
    import SettingsService from '@/services/SettingsService'
    import CommonDialog from '@/components/controls/CommonDialog'

    export default ({
        components: {
            CommonDialog
        },

        props: {
            votingTypeId: {
                type: Number,
                default: null
            },
            value: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                isPublic: false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('NameAr'), value: 'nameAr' },
                    { text: this.$t('NameEn'), value: 'nameEn' },
                    { text: this.$t('Active'), value: 'active' },
                    { text: this.$t('DisplayOrder'), value: 'displayOrder' },
                    { text: this.$t('Weight'), value: 'weight' },
                    { text: this.$t('Actions'), value: 'actions' }
                ],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                voteOptionModel: {
                    id: 0,
                    nameAr: null,
                    nameEn: null,
                    active: false,
                    weight: null,
                    displayOrder: null,
                    votingTypeId: null

                },
                requiredRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                voteOptions: [],
                selectedOption: null,
            }
        },
        computed: {

        },
        created() {
            if (!this.votingTypeId) {
                this.close()
            }
            this.voteOptionModel.votingTypeId = this.votingTypeId
            this.loadVotingOptions()
        },
        mounted() {

        },
        methods: {

            EnableEdit(option) {
                this.selectedOption = option
                this.voteOptionModel.id = option.id
                this.voteOptionModel.nameAr = option.nameAr
                this.voteOptionModel.nameEn = option.nameEn
                this.voteOptionModel.active = option.active
                this.voteOptionModel.displayOrder = option.displayOrder
                this.voteOptionModel.weight = option.weight

            },

            CancelEdieVoteOption() {
                this.voteOptionModel.id = 0
                this.voteOptionModel.nameAr = null
                this.voteOptionModel.nameEn = null
                this.voteOptionModel.active = false
                this.voteOptionModel.displayOrder = null
                this.voteOptionModel.weight = null
                this.selectedOption = null

            },
            loadVotingOptions() {
                SettingsService.listAllVotingOptions(this.votingTypeId).then((res) => {
                    this.voteOptions = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            AddVotingOption() {
                if (this.$refs.voteForm.validate()) {
                    this.loading = true
                    SettingsService.addVotingTypeOption(this.voteOptionModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('AddedSuccess'), 'success')
                            this.CancelEdieVoteOption()
                            this.loadVotingOptions()
                        } else {
                            this.$root.$notification(this.$t('HEEEEE'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }

            },

            EditVotingOption() {
                if (this.$refs.voteForm.validate()) {
                    this.loading = true
                    SettingsService.updateVotingTypeOption(this.voteOptionModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                            this.CancelEdieVoteOption()
                            this.loadVotingOptions()

                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }
            },
            deleteVote(votingOptionId) {
                this.CancelEdieVoteOption()
                this.$refs.confirmDialog.open(this.$t('DeleteVotingOption'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            SettingsService.deleteVotingTypeOption(votingOptionId).then((res) => {
                                if (res.success) {
                                    this.loadVotingOptions()
                                } else {
                                    this.$root.$notification(res.message, 'error')
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            }

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>