<template>
    <v-card class="rounded-lg pa-0" hover height="100%">
        <v-card-text class="text-center">
            <h3 class="teal-color text-center mb-4">{{ title }}</h3>
            <apexchart type="bar" :height="height" :options="barOptions" :series="series" />
        </v-card-text>
    </v-card>
</template>

<script>
    import apexchart from 'vue-apexcharts'

    export default {

        components: {
            apexchart
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: 250
            },
            series: {
                type: Array,
                default: () => []
            },
            labels: {
                type: Array,
                default: () => []
            },
            horizontal: {
                type: Boolean,
                default: false
            },
            showBottomLabels: {
                type: Boolean,
                default: true
            },
            isArabic: {
                type: Boolean,
                default: false
            },
            distributed: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                barOptions: {
                    chart: {
                        type: 'bar',
                        fontFamily: 'Tajawal',
                        toolbar: {
                            show: true,
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: this.horizontal,
                            distributed: this.distributed,
                            columnWidth: '10%',
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        position: 'top',
                        style: {
                            colors: ['#fff']
                        },
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        show: false,
                        width: 2,
                    },

                    xaxis: {
                        categories: this.labels,
                        labels: {
                            show: this.showBottomLabels,
                            rotate: this.isArabic ? 20 : 340,
                            rotateAlways: false,
                            maxHeight: 100,
                            style: {
                                fontSize: '10px',
                                fontFamily: 'Tajawal',
                                fontWeight: '600'
                            }
                        }
                    },
                    colors: ['#52dbce', '#8eaac9', '#b71c1c', '#1565c0', '#000'],
                    fill: {
                        opacity: 1
                    },
                },

            }
        }
    }
</script>

<style>
    .apexcharts-legend-text {
        padding: 0 5px !important
    }
</style>