<template>
    <div>
        <div class="card-title ">
            <div>
                {{ $t('Attachments') }}
                <v-icon v-if="!viewMode" class="float-end me-2 mb-4" color="primary" @click="openAddAttachmentsDialogue">
                    mdi-plus-circle-outline
                </v-icon>
            </div>
        </div>
        <div class="uploadedFile-info">
            <v-simple-table class="mt-4">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                {{ $t('Icon') }}
                            </th>
                            <th>
                                {{ $t('FileName') }}
                            </th>
                            <th>
                                {{ $t('FileSize') }}
                            </th>
                            <th>
                                {{ $t('FileExtension') }}
                            </th>
                            <th>
                                {{ $t('Agenda') }}
                            </th>
                            <th>
                                {{ $t('PrivacyLevel') }}
                            </th>
                            <th class="text-center">
                                {{ $t('Actions') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in files" :key="index">
                            <td>
                                <v-icon>{{ icons[item.name.split('.').pop()] }}</v-icon>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.name.split('.').pop() }}</td>
                            <td>{{ getAgendaForFile(item) }}</td>
                            <td>{{ item.privacyName }}</td>
                            <td class="text-center">
                                <v-icon v-if="!viewMode" color="error" @click="removeFile(item)">mdi-trash-can-outline</v-icon>
                                <v-icon v-if="!viewMode" color="primary" class="ms-2" @click="selectAgendaForFile(item)">mdi-pencil</v-icon>
                                <v-icon v-if="item.id" color="primary" class="ms-2" @click="loadAttachment(item.id)">mdi-eye</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-dialog v-if="showSelectFileAgendaDialogue" persistent width="400" :title="$t('Attachment')" v-model="showSelectFileAgendaDialogue">
            <select-file-agenda :agenda-items="meetingAgendas" :attachment-id="selectedAttachmentId" :meeting-id="meetingId" :agenda-id="selectedAgendaId"
                                :privacy-id="selectedPrivacyId" @close="showSelectFileAgendaDialogue=false" @updateAttachments="updateAttachments" />

        </v-dialog>

        <CommonDialog v-model="attachmentDialog" :title="$t('Attachment')" css-content-class="pa-0" @input="attachmentDialog= false">
            <v-container fluid class="stretch">
                <FileViewer :query="documentQuery" name="meetingAttachment" />
            </v-container>
        </CommonDialog>
        <v-dialog v-if="addAttachmentDialog" v-model="addAttachmentDialog" width="1000">
            <AddMeetingAttachment :meeting-id="meetingId" @close="addAttachmentDialog = false" @updateAttachments="updateAttachments" />
        </v-dialog>
    </div>
</template>

<script>
    import FileViewer from '@/components/controls/FileViewer.vue'
    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import AttachmentsService from '@/services/AttachmentsService.js'
    import MeetingsService from '@/services/MeetingsService'
    import SelectFileAgenda from '@/components/app/meeting/SelectFileAgenda.vue'
    import AddMeetingAttachment from '@/components/app/meeting/AddMeetingAttachment.vue'
    import { AttachmentsRecordType } from '@/helpers/constants/enumerations'

    export default {
        components: {
            CommonDialog, FileViewer, SelectFileAgenda, AddMeetingAttachment
        },

        props: {
            meetingAgendas: {
                type: Array,
                default: () => []
            },
            meetingId: {
                type: Number,
                default: null

            },
            viewMode: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                files: [],
                file: '',
                loading: false,
                attachmentDialog: false,
                addAttachmentDialog: false,
                dragging: false,
                documentQuery: null,
                showSelectFileAgendaDialogue: false,
                selectedAttachmentId: null,
                selectedPrivacyId: null,
                selectedAgendaId: null,
                icons: {
                    'pdf': 'mdi-file-pdf-box mdi-30px',
                    'docx': 'mdi-file-word mdi-30px',
                    'xlsx': 'mdi-file-excel mdi-30px',
                    'file': 'mdi-file mdi-30px',
                    'png': 'mdi-file-image mdi-30px',
                    'jpeg': 'mdi-file-image mdi-30px',
                    'jpg': 'mdi-file-image mdi-30px',
                    'txt': 'mdi-text-box-outline mdi-30px',
                    'rar': 'mdi-folder-zip mdi-30px',
                    'exe': 'mdi-cog mdi-30px',
                },
            }
        },
        created() {

           if(this.meetingId) this.loadMeetingAttachment()
        },
        methods: {
            loadMeetingAttachment() {
                MeetingsService.listMeetingAttachments(this.meetingId).then((res) => {
                    this.files = res.data
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            loadAttachment(attachmentId) {
                AttachmentsService.getAttachment(attachmentId).then(response => {
                    this.documentQuery = response.data
                    this.attachmentDialog = true
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },

            removeFile(attachment) {
                this.loading = true
                MeetingsService.removeMeetingAttachment(this.meetingId, attachment.id).then(response => {
                    if (response.data) {
                        this.updateAttachments(response.data)
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

            },
            selectAgendaForFile(attachment) {
                this.selectedAttachmentId = attachment.id
                this.selectedPrivacyId = attachment.privacyId
                this.selectedAgendaId = null
                if (attachment.recordTypeId == AttachmentsRecordType.MeetingAgenda) {
                    var agenda = this.meetingAgendas.find(x => x.id == attachment.recordId)
                    this.selectedAgendaId = agenda?.id
                }
                this.showSelectFileAgendaDialogue = true
            },
            updateAttachments(attachments) {
                this.files = attachments
                this.$emit('input', this.files)

            },

            getAgendaForFile(item) {
                if (item.recordTypeId == AttachmentsRecordType.Meeting) return null
                else {
                    var agenda = this.meetingAgendas.find(x => x.id == item.recordId)
                    return agenda?.title
                }
            },
            openAddAttachmentsDialogue() {
                this.addAttachmentDialog = true
            },
        },
    }
</script>
<style>
    .dropZone {
        width: 100%;
        height: 200px;
        position: relative;
        border: 2px dashed #b4b4b4;
    }

        .dropZone:hover {
            border: 2px solid var(--v-primary-base);
        }

            .dropZone:hover .dropZone-title {
                color: var(--v-primary-base);
                font-weight: 600
            }

    .dropZone-info {
        color: #A8A8A8;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translate(0, -50%);
        text-align: center;
    }

    .dropZone-title {
        color: var(--v-primary-base);
        font-weight: 600
    }

    .dropZone input {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .dropZone-upload-limit-info {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .dropZone-over {
        background: #5C5C5C;
        opacity: 0.8;
    }

    .dropZone-uploaded {
        width: 100%;
        height: 200px;
        position: relative;
        border: 2px dashed #eee;
    }

    .dropZone-uploaded-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #A8A8A8;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translate(0, -50%);
        text-align: center;
    }
</style>