<template>
    <v-container fluid>
        <v-row>
            <v-col cols="3">
                <v-card class="rounded-lg">
                    <v-card-title>
                        {{ $t('Filters') }} <v-spacer />
                        <v-icon class="float-end">mdi-plus</v-icon>
                    </v-card-title>
                    <v-divider class="custom-separator"/>
                    <v-card-text>
                        <v-chip v-for="(status, index) in statusTypes" :key="index"
                                label
                                :color="status.color"
                                :class="['rounded-xl','d-block', 'my-4','white--text', status.active ? 'active-chip' : '']"
                                @click="toggleActive(index)"> 
                            {{ status.title }}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-card height="82vh" class="rounded-lg pa-2">
                    <v-toolbar elevation="0">
                        <v-btn large class="ma-2" color="secondary" @click="setToday" text>
                            <span class="mms-title">{{ $t('Today') }}</span>
                        </v-btn>
                        <v-spacer />
                        <span v-if="$refs.eventsCalendar">
                            <v-btn icon class="ma-2" @click="$refs.eventsCalendar.prev()">
                                <v-icon v-if="$i18n.locale =='ar'" color="#b0b7c3">mdi-chevron-right</v-icon>
                                <v-icon v-else color="secondary">mdi-chevron-left</v-icon>
                            </v-btn>
                            <span class="mms-title"> {{ $refs.eventsCalendar.title }}</span>
                            <v-btn icon
                                   class="ma-2"
                                   @click="$refs.eventsCalendar.next()">
                                <v-icon v-if="$i18n.locale =='ar'" color="#b0b7c3">mdi-chevron-left</v-icon>
                                <v-icon v-else color="secondary">mdi-chevron-right</v-icon>
                            </v-btn>
                        </span>
                        <v-spacer />
                        <v-btn-toggle v-model="type" borderless color="primary" class="ms-3">
                            <v-btn value="month" text>
                                <span class="hidden-sm-and-down">{{ $t('Monthly') }}</span>
                                <v-icon right>
                                    mdi-calendar-month
                                </v-icon>
                            </v-btn>

                            <v-btn value="week" text>
                                <span class="hidden-sm-and-down">{{ $t('Weekly') }}</span>
                                <v-icon right>
                                    mdi-calendar-week
                                </v-icon>
                            </v-btn>

                            <v-btn value="day" text>
                                <span class="hidden-sm-and-down">{{ $t('Daily') }}</span>
                                <v-icon right>
                                    mdi-calendar-today
                                </v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-toolbar>

                    <v-sheet height="73vh">
                        <v-calendar ref="eventsCalendar"
                                    v-model="focus"
                                    :locale="locale"
                                    :event-height="25"
                                    :weekdays="weekday"
                                    color="primary"
                                    :type="type"
                                    :events="filteredEvents"
                                    :event-overlap-mode="mode"
                                    @click:event="showEvent"
                                    @click:more="viewDay"
                                    @click:date="viewDay"
                                    @change="getEvents">

                            <template v-slot:day="{ date, outside }">
                                <div :class="['hijri-date','v-calendar-day__text','ms-2', { 'text-muted': outside }]">
                                    {{date |formatDateAr }}
                                </div>
                            </template>
                            <template v-slot:event="{ event }">
                                {{ event.name }}
                                <v-icon class="float-end" color="white" v-if="event.statusId == 4">mdi-video-check</v-icon>
                                <v-icon class="float-end" color="white" v-if="event.typeId == 3" @click="copyToClipboard">mdi-open-in-new</v-icon>
                            </template>
                        </v-calendar>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog v-if="meetingOpened" v-model="meetingOpened" fullscreen hide-overlay persistent>
            <meeting-dashboard :meeting="selectedEvent" @close="meetingOpened = false" />
        </v-dialog>
    </v-container>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    import { mapGetters } from 'vuex'
    import { MeetingTypeEnum } from '@/helpers/constants/enumerations.js'

    export default {

        components: {
        },
        data() {
            return {
                selectedEvent: null,
                meetingOpened: false,
                selectedOpen: false,
                locale: '',
                focus: '',
                type: 'month',
                selectedStatus: 0,
                statusTypes: [
                    { title: this.$t('All'), color: '#6f9ff2', id: 0, active: true },
                    { title: this.$t('Pending'), color: '#884dff', id: 2, active: false },
                    { title: this.$t('Approved'), color: '#52dbce', id: 3, active: false },
                    { title: this.$t('PendingInitialAgendaApproval'), color: '#f2af6f', id: 4, active: false },
                    { title: this.$t('InitialAgendaApproved'), color: '#4fbab0', id: 5, active: false },
                ],
                types: ['month', 'week', 'day', '4day'],
                mode: 'stack',
                weekday: [0, 1, 2, 3, 4, 5, 6],

                events: [],
                filteredEvents: [],
                colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
                calenderType: 'Gregorian',
            }
        },

        computed: {
            ...mapGetters('user', ['language']),

        },

        mounted() {
            this.locale = this.language
        },

        created() {
        },

        methods: {
            setToday() {
                this.focus = ''
            },
            viewDay({ date }) {
                this.focus = date
                this.type = 'day'
            },
            getEvents({ start, end }) {

                const min = new Date(`${start.date}T00:00:00`)
                const max = new Date(`${end.date}T23:59:59`)
                this.loadMeetings(min, max)
            },

            loadMeetings(startDate, endDate) {
                let formData = {
                    'startDate': startDate,
                    'endDate': endDate,
                }
                MeetingsService.ListUserMeetingsForCalender(formData).then(res => {
                    if (res.success) {

                        let apiEvenets = res.data
                        //map meetings to match calender events (name-start-end-timed-color)/other properties for customize use (id,statusId)
                        this.events = apiEvenets.map(event => ({
                            id: event.id,
                            name: event.title,
                            start: new Date(event.date.slice(0, 10) + 'T' + event.startTime),
                            end: new Date(event.date.slice(0, 10) + 'T' + event.endTime),
                            timed: (event.startTime && event.endTime),
                            color: this.colors[event.statusId],
                            statusId: event.statusId,
                            typeId: event.meetingTypeId,
                            url: event.meetingUrl
                        }))
                        this.filteredEvents = this.events
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false

                })
            },
            toggleActive(index) {
                this.selectedStatus = this.statusTypes[index].id
                this.filteredEvents = this.events.filter(event => this.selectedStatus == 0 || event.statusId == this.selectedStatus)
                this.statusTypes.forEach((status, i) => {
                    status.active = i === index ? !status.active : false
                })
            },
            showEvent({ nativeEvent, event }) {

                if (this.meetingOpened) return

                const open = () => {
                    this.selectedEvent = event
                    requestAnimationFrame(() => requestAnimationFrame(() => this.meetingOpened = true))
                }

                requestAnimationFrame(() => requestAnimationFrame(() => open()))

                nativeEvent.stopPropagation()

            },
            closeEventDetails() {
                this.selectedOpen = false
            },
            copyToClipboard() {
                let copyText = meetingDetails.url
                navigator.clipboard.writeText(`${copyText}`)
                this.$root.$notification(this.$t('CopySucceeded'), 'success')
            }

        }
    }
</script>
<style scoped>
    .active-chip {
        border: 2px solid #4caf50;
        box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    }

    .text-muted {
        color: #bdbdbd;
    }

    .theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
        font-weight: 600 !important;
        font-size: 16px;
        padding-top: 5px
    }
</style>