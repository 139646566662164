<template>
    <v-card class="rounded-lg" :loading="loading">
        <v-card-title>
            <v-text-field v-model="search" outlined clearable append-icon="mdi-magnify" :label="$t('Search')" single-line hide-details />
        </v-card-title>
        <v-card-text>
            <v-data-table :search="search" :headers="headers" :items="result" :items-per-page="10" :footer-props="footerProps">
                <template v-slot:top>
                    <v-btn class="ma-4 white--text rounded-xl" color="primary" @click="open()">
                        {{ $t('Add') }} <v-icon small class="ms-2">mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <v-icon color="primary" class="ms-3" @click="editItem(item)"> mdi-pen</v-icon>
                    <v-icon color="error" class="ms-3" @click="deleteItem(item.id)">mdi-trash-can-outline</v-icon>
                </template>
            </v-data-table>
        </v-card-text>
        <v-form ref="dictionaryForm" v-model="valid" lazy-validation>
            <CommonDialog v-model="dialog" :fullscreen="false" width="500" :title="title" show-action-toolbar>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field outlined v-model="dictionary.keyword" :label="$t('Keyword')" :rules="nameRules" required />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined v-model="dictionary.ar" :label="$t('NameAr')" :rules="nameRules" required />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field outlined v-model="dictionary.en" :label="$t('NameEn')" :rules="nameRules" required />
                        </v-col>
                    </v-row>
                </v-container>

                <template v-slot:actions>
                    <v-btn v-if="!edit" color="primary" class="white--text"  :disabled="loading"  @click="save()">
                        {{ $t("Add") }}
                    </v-btn>
                    <v-btn v-if="edit" color="primary" class="white--text"  :disabled="loading"  @click="update()">
                        {{ $t("Update") }}
                    </v-btn>
                    <v-btn color="error" class="white--text"  :disabled="loading"  @click="dialog = false; reset()">
                        {{ $t("Cancel") }}
                    </v-btn>
                </template>
            </CommonDialog>
        </v-form>
    </v-card>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import DictionaryService from '@/services/DictionaryService.js'

    export default {
        components: {
            CommonDialog
        },

        data() {
            return {
                title: '',
                search: '',
                valid: true,
                loading: false,
                dialog: false,
                edit: false,
                selectedItemId: null,
                result: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Keyword'), value: 'keyword' },
                    { text: this.$t('TitleAr'), value: 'ar' },
                    { text: this.$t('TitleEn'), value: 'en' },
                    { text: this.$t('Options'), value: 'options', width: '8%' }
                ],
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right'
                },
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                dictionary: {
                    keyword: '',
                    ar: '',
                    en: '',
                }
            }
        },

        created() {
            this.loadDictionary()
        },

        methods: {
            loadDictionary() {
                this.loading = true
                DictionaryService.listDictionary().then((res) => {
                    if (res.data) {
                        this.result = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            save() {
                if (this.$refs.dictionaryForm.validate()) {
                    DictionaryService.addDictionary(this.dictionary).then((res) => {
                        this.loadDictionary()
                        this.reset()
                        this.$refs.dictionaryForm.resetValidation()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            update() {
                if (this.$refs.dictionaryForm.validate()) {
                    DictionaryService.updateDictionary(this.selectedItemId, this.dictionary).then((res) => {
                        this.dialog = false
                        this.loadDictionary()
                        this.reset()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            async deleteItem(id) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    DictionaryService.deleteDictionary(id)
                        .then(() => {
                            this.loadDictionary()
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            reset() {
                this.dictionary.ar = ''
                this.dictionary.en = ''
                this.dictionary.keyword = ''
                this.selectedItemId = null
            },

            open() {
                this.reset()
                this.edit = false
                this.selectedItemId = null
                this.$refs.dictionaryForm.resetValidation()
                this.title = this.$t('Add')
                this.dialog = true
            },

            editItem(item) {
                this.edit = true
                this.dictionary.keyword = item.keyword
                this.dictionary.ar = item.ar
                this.dictionary.en = item.en
                this.selectedItemId = item.id
                this.title = this.$t('Edit')
                this.dialog = true
            },
        },
    }
</script>

<style>
</style>