<template>
    <v-card tile min-height="300" class="rounded-lg" :loading="loading">
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="votingTypes"
                          :items-per-page="10" :footer-props="footerProps">
                <template v-slot:top>
                    <v-btn class="ma-4 white--text rounded-xl" color="primary" @click="openAddType">
                        {{ $t('Add') }} <v-icon small class="ms-2">mdi-plus</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <v-icon color="black" size="22" @click="showTypeOptions(item.id)"> mdi-format-list-numbered</v-icon>
                    <v-icon color="primary" size="22" class="ms-2" @click="openEditType(item)"> mdi-pencil  </v-icon>
                    <v-icon color="error" size="22" class="ms-2" @click="deleteVotingType(item.id)"> mdi-trash-can-outline</v-icon>

                </template>
                <template v-slot:[`item.active`]="{ item }">
                    <v-icon v-if="item.active" color="teal">mdi-check</v-icon>
                    <v-icon v-else color="error"> mdi-close</v-icon>
                </template>
            </v-data-table>
        </v-card-text>

        <AddVotingTypeDialogue v-if="showTypeDialogue" v-model="showTypeDialogue" :voting-type="selectedType" @close="showTypeDialogue = false" @update="listVotingTypes" />

        <VotingTypeOptions v-if="showTypeOptionsDialogue" v-model="showTypeOptionsDialogue" :voting-type-id="selectedTypeId" @close="showTypeOptionsDialogue = false" />

    </v-card>
</template>

<script>
    import SettingsService from '@/services/SettingsService'
    import AddVotingTypeDialogue from '@/components/app/admin/UpsertVotingType.vue'
    import VotingTypeOptions from '@/components/app/admin/VotingTypeOptions.vue'

    export default {
        components: {
            AddVotingTypeDialogue, VotingTypeOptions
        },
        data() {
            return {
                loading: false,
                selectedType: null,
                selectedTypeId: null,
                showTypeOptionsDialogue: false,
                showTypeDialogue: false,
                votingTypes: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('NameAr'), value: 'nameAr' },
                    { text: this.$t('NameEn'), value: 'nameEn' },
                    { text: this.$t('Active'), value: 'active' },
                    { text: this.$t('DisplayOrder'), value: 'displayOrder' },
                    { text: this.$t('Options'), value: 'options' }
                ],
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false,
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right'
                },
            }
        },

        created() {
            this.listVotingTypes()
        },

        methods: {


            listVotingTypes() {
                this.loading = true
                SettingsService.listAllVotingTypes()
                    .then((res) => {
                        if (res.data) {
                            this.votingTypes = res.data
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },
            openEditType(type) {
                this.selectedType = type
                this.showTypeDialogue = true


            },
            openAddType() {
                this.selectedType = null
                this.showTypeDialogue = true
            },
            async deleteVotingType(votingTypeId) {

                if (await this.$root.$confirm(this.$t('Delete'), this.$t('DeleteVotingTypeConfirm'))) {
                    this.loading = true
                    SettingsService.deleteVotingType(votingTypeId).then((res) => {
                        if (res.success) {
                            this.listVotingTypes()
                        } else {
                            this.$root.$notification(res.message, 'error')
                        }

                    }).catch(() => {
                        this.$root.$notification(this.$t('DeleteFailedRelated'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
            showTypeOptions(typeId) {
                this.selectedTypeId = typeId
                this.showTypeOptionsDialogue = true

            }
        }
    }
</script>

<style>
</style>