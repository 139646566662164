<template>
    <v-card class="pa-0 elevation-0 rounded-xl" hover height="100%">
        <v-card-text class="text-center">
            <h3 class="teal-color text-center mb-3">{{ title }}</h3>
            <apexchart :height="height" type="donut" :options="pieOptions" :series="pieOptions.series" />
        </v-card-text>
    </v-card>
</template>

<script>
    import apexchart from 'vue-apexcharts'

    export default {

        components: {
            apexchart
        },
        props: {
            title: {
                type: String,
                default: ''
            },
            height: {
                type: Number,
                default: null
            },
            series: {
                type: Array,
                default: () => []
            },
            labels: {
                type: Array,
                default: () => []
            },
            type: {
                type: String,
                default: 'donut'
            },
        },

        data() {
            return {
                pieOptions: {
                    series: this.series,
                    colors: ['#f2af6f', '#456191', '#022524', '#dfe8e8', '#0e0e0e', '#e4f0d7'],
                    labels: this.labels,
                    chart: {
                        type: this.type,
                        fontFamily: 'Tajawal',
                        toolbar: {
                            show: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {

                            },
                            legend: {
                                show: false
                            }
                        }
                    }],
                    legend: {
                        position: 'bottom',
                    }
                },
            }
        }
    }
</script>

<style>

    .apexcharts-legend-text {
        padding: 10px 5px !important;
        margin-inline-start: 5px !important;
        font-size: 14px !important;
    }

    .apexcharts-legend-series {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
        /* Margin adjustment for legend dots */
        .apexcharts-legend-series path {
            margin-right: 15px !important;
        }
</style>
