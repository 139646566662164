<template>
    <v-breadcrumbs>
        <v-breadcrumbs-item :class="{ 'bread-selected': parents.length==0, 'not-selected': parents.length >0 }" @click="first">
            <span class="mms-title"> {{ $t('CouncilsAndCommitteesData') }}</span>
        </v-breadcrumbs-item>
        <template v-for="(comittee,index) in parents">
            <div :key="'breadcrumb'+index">
                <v-icon small class="ps-2 pe-2">mdi-slash-forward</v-icon>
                <v-breadcrumbs-item :class="{ 'bread-selected': index==parents.length-1, 'not-selected': index!=parents.length-1 }" @click="second(comittee,index)">
                    <span class="mms-title">  {{ comittee.name }}</span>
                </v-breadcrumbs-item>
            </div>
        </template>
    </v-breadcrumbs>
</template>

<script>
    export default {
        props: {
            parents: {
                type: Array,
                default: () => []
            },
        },
        methods: {
            first() {
                if (this.$route.path !== '/council-comittee-general-info') {
                    this.$router.push({ name: 'council-comittee-general-info' })
                }
                /*else {
                    this.$emit('input', null)
                }*/
            },

            second(comittee, index) {

                if (index == this.parents.length - 1) return

                if (this.$route.path !== '/council-comittee-general-info/' + comittee.id) {
                    this.$router.push({ name: 'council-comittee-general-info', params: { councilId: comittee.id } })
                }

            }
        }
    }
</script>

<style scoped>
    .selected,
    .not-selected {
        cursor: pointer;
    }

    .bread-selected span{
        color: var(--v-primary-base) !important
    }
</style>