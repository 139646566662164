<template>
    <CommonDialog :value="value" :title="$t('Signature')" width="500" :fullscreen="false" show-action-toolbar @input="$emit('input', false)">
        <template v-slot:actions>
            <v-btn v-if="!saved" color="secondary" class="white--text" :disabled="loading" small @click="save()">
                {{ $t("Add") }}
            </v-btn>
        </template>

        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-tabs v-model="tab" fixed-tabs>
                        <v-tab>{{ $t("UploadSignature") }}</v-tab>
                        <v-tab>{{ $t("DrawSignature") }}</v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-col cols="12" class="mt-2">
                                    <v-file-input v-model="signature"
                                                  dense
                                                  accept=".png"
                                                  :label="$t('ChooseSignature')"
                                                  :multiple="false"
                                                  @change="fileInputChange" />
                                </v-col>
                            </v-tab-item>
                            <v-tab-item>
                                <v-col cols="12">
                                    <v-card elevation="2" tile>
                                        <div class="bottom-border">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon color="red lighten-2" v-on="on" @click="clear()">
                                                        <v-icon>mdi-broom</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("Clear") }}</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon color="secondary" v-on="on" @click="padOptions={penColor:'#000'}">
                                                        <v-icon>mdi-square</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("BlackColor") }}</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn text icon color="#00f" v-on="on" @click="padOptions={penColor:'#00f'}">
                                                        <v-icon>mdi-square</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t("BlueColor") }}</span>
                                            </v-tooltip>
                                        </div>
                                        <v-card-text>
                                            <VueSignaturePad ref="signaturePad" height="75px" :options="padOptions" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                    <v-row class="mt-2">
                        <v-col cols="6">
                            <v-form ref="pincodeForm" v-model="valid" lazy-validation>
                                <v-text-field v-model="pincode"
                                              outlined
                                              :rules="[required, lengthFour,isInteger]"
                                              dense
                                              :type="showPinCode ? 'text' : 'password'"
                                              :append-icon="showPinCode ? 'mdi-eye' : 'mdi-eye-off'"
                                              :label="$t('Pincode')"
                                              @click:append="showPinCode = !showPinCode" />
                            </v-form>
                        </v-col>

                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="2" tile>
                        <v-card-text>
                            <v-row>
                                <v-col v-for="(imageItem, index) in images" :key="index" cols="4">
                                    <div class="bordered text-center">
                                        <v-img aspect-ratio="1.7" contain :src="`data:image/png;base64,${imageItem.name}`" />
                                        <v-spacer />
                                        <v-icon size="20" color="error" @click="deleteSignature(imageItem.id)">mdi-trash-can-outline</v-icon>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </CommonDialog>
</template>
<script>
    import UsersService from '@/services/UsersService.js'

    import CommonDialog from '@/components/controls/CommonDialog.vue'

    export default {

        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: null
            },
        },

        data() {
            return {
                showPinCode: false,
                signature: null,
                pincode: null,
                image: null,
                images: [],
                tab: 0,
                loading: false,
                saved: true,
                padOptions: {
                    onBegin: () => {
                        this.$refs.signaturePad.resizeCanvas()
                        this.saved = false
                    },
                    penColor: '#000'
                },
                required: value => !!value || this.$t('PincodeValidationError'),
                lengthFour: v => this.IsValidInteger(v) || this.$t('NotValidInteger'),
                isInteger: v => (v && v.length == 4) || this.$t('PincodeLengthError'),
            }
        },

        created() {
            this.loadSignatures()
        },

        methods: {
            IsValidInteger(str) {
                var n = Math.floor(Number(str))
                return n !== Infinity && String(n) === str && n >= 0
            },
            save() {
                if (!this.$refs.pincodeForm.validate()) {
                    return
                }
                /*if (!this.pincode || this.pincode.trim().length <4) {
                    this.$root.$notification(this.$t('PincodeValidationError'), 'error')
                }*/
                const isEmptyCanvas = !this.$refs.signaturePad || this.$refs.signaturePad.isEmpty()
                if (this.signature != null || !isEmptyCanvas) {
                    this.loading = true
                    let formData = new FormData()
                    formData.append('pincode', this.pincode)
                    if (this.tab == 0 && this.signature != null) {
                        formData.append('signature', this.signature)
                    } else {
                        if (this.tab == 1 && !isEmptyCanvas) {
                            formData.append('signature', this.$refs.signaturePad.saveSignature().data.replace('data:image/png;base64,', ''))
                        } else {
                            if (this.signature != null) {
                                formData.append('signature', this.signature)
                            } else {
                                formData.append('signature', this.$refs.signaturePad.saveSignature().data.replace('data:image/png;base64,', ''))
                            }
                        }
                    }

                    UsersService.setSignature(formData)
                        .then((res) => {
                            if (!res.success) {
                                if (!res.data) {
                                    this.$root.$notification(this.$t(res.data.message), 'error')
                                } else {
                                    this.$root.$notification(`${this.$t(res.data.message)} (${res.data.data})`, 'error')
                                }
                            }
                            else {
                                this.saved = true
                                this.signature = null
                                if (this.$refs.signaturePad) {
                                    this.$refs.signaturePad.clearSignature()
                                }
                            }
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        })
                        .finally(() => {
                            this.loadSignatures()
                            this.loading = false
                        })
                }
            },

            loadSignatures() {
                this.loading = true
                UsersService.getSignatures().then((res) => {
                    if (res.success) {
                        this.images = res.data
                    } else {
                        this.image = null
                    }
                }).catch(() => {
                    this.image = null
                })
                    .finally(() => {
                        this.loading = false
                    })
            },

            fileInputChange(signatureFile) {
                this.saved = signatureFile == null
            },

            async deleteSignature(id) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    UsersService.deleteSignature(id)
                        .then(() => {
                            this.loadSignatures()
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            clear() {
                this.$refs.signaturePad.clearSignature()
                this.saved = true
            }
        }
    }
</script>
<style scoped>
    .bottom-border {
        border-bottom: solid 1px rgb(167, 163, 163);
    }

    canvas {
        cursor: crosshair !important;
    }

    .bordered {
        border: 1px solid #ddd
    }
</style>