<template>
    <div>
        <v-card :loading="loading">
            <div class="card-title ">
                <div>
                    {{ $t('AgendaRecommendations') }}
                </div>
            </div>
            <GridWithServerPaging :headers="headers" :items="tasks" :items-per-page="options.itemsPerPage" :total="totalCount" disable-sort class="mt-4" @onChange="loadTasks">
                <template v-slot:[`item.preview`]="{ item }">
                    <v-icon color="primary" @click="openManageRecommendationPage(item)"> mdi-eye</v-icon>
                </template>
            </GridWithServerPaging>
        </v-card>
    </div>
</template>

<script>
    import CouncilCommitteesService from '@/services/CouncilCommitteesService'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default ({
        components: {
            GridWithServerPaging
        },

        props: {
            id: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('ReferenceNumber'), value: 'meetingReferenceNo' },
                    { text: this.$t('MeetingDate'), value: 'createdAt' },
                    { text: this.$t('Title'), value: 'text', width: '25%' },
                    { text: this.$t('CreatedBy'), value: 'createdByName' },
                    { text: this.$t('Progress'), value: 'percentage' },
                    { text: this.$t('Status'), value: 'status' },
                    { text: this.$t('Actions'), value: 'preview' },
                ],
                tasks: [],
                options: {
                    page: 1, itemsPerPage: 5
                },
                totalCount: null,
            }
        },
        computed: {

        },
        watch: {
        },

        created() {
        },

        mounted() {
            this.loadTasks(this.options)
        },
        methods: {

            loadTasks(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                CouncilCommitteesService.listCouncilCommitteeTasks(this.id, page, pageSize).then((res) => {
                    this.tasks = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            openManageRecommendationPage(recommendation) {
                this.$router.push({ name: 'recommendation-details', params: { id: recommendation.id, viewMode: true } })
            },

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>