<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('AddRecommendation') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card tile class="pa-2 pt-4">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-text-field v-model="text" :rules="cleanRequiredStringRules" outlined :label="$t('AgendaRecommendation')" />
                    </v-col>
                    <v-col cols="6 ">
                        <v-select v-model="selectedOwner"
                                  :items="attendees"
                                  item-text="name"
                                  item-value="userId"
                                  solo
                                  :label="$t('Owner')"
                                  outlined />
                    </v-col>
                </v-row>
                <v-card-actions class="justify-center ">
                    <v-btn v-show="!selectedRecommendation" class="mb-3" color="teal" dark @click="AddMeetingAgendaRecommendation">
                        {{ $t('Add') }}
                    </v-btn>
                    <v-btn v-show="selectedRecommendation" class="mb-3" color="teal" dark @click="EditAgendaRecommendation">
                        {{ $t('Edit') }}
                    </v-btn>
                    <v-btn v-show="selectedRecommendation" class="mb-3" color="teal" dark @click="CancelEditRecommendation">
                        {{ $t('Cancel') }}
                    </v-btn>
                </v-card-actions>


                <v-data-table :headers="headers"
                              dense
                              item-key="id"
                              :items="recommendations"
                              :items-per-page="5" hide-default-footer>
                    <template v-slot:[`item.percentage`]="{ item }">
                        {{ item.percentage+' % ' }}
                    </template>
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt| formatDateAr }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon v-if="item.createdBy == loggedInUser?.id"
                                class="mx-1"
                                :disabled="loading"
                                color="warning"
                                @click="EnableEdit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon v-if="item.createdBy == loggedInUser?.id"
                                color="error" class="ms-3"
                                :disabled="loading"
                                @click="deleteRecommendation(item.id)">
                            mdi-trash-can-outline
                        </v-icon>
                    </template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MeetingAgendaService from '@/services/MeetingAgendaService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            ConfirmDialog
        },

        props: {
            id: {
                type: Number,
                default: null
            },
            attendees: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                selectedOwner: null,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Text'), value: 'text' },
                    { text: this.$t('CreatedBy'), value: 'createdByName' },
                    { text: this.$t('CreatedDate'), value: 'createdAt' },
                    { text: this.$t('Owner'), value: 'ownerName' },
                    { text: this.$t('Status'), value: 'status' },
                    { text: this.$t('CompletePercentage'), align: 'center', value: 'percentage' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                recommendations: [],
                selectedRecommendation: null,
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

        },
        watch: {
        },

        created() {
        },

        mounted() {
            this.loadRecommendations()
        },
        destroyed() {
        },
        methods: {
            AddMeetingAgendaRecommendation() {
                this.loading = true
                let formData = {
                    'meetingAgendaId': this.id,
                    'text': this.text,
                    'owner': this.selectedOwner
                }
                MeetingAgendaService.addMeetingAgendaRecommendation(formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('AddRecommendationSuccess'), 'success')
                        this.loadRecommendations()
                        this.CancelEditRecommendation()
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            EnableEdit(recommendation) {
                this.selectedRecommendation = recommendation
                this.text = recommendation.text
                this.selectedOwner = recommendation.owner

            },
            EditAgendaRecommendation() {
                this.loading = true
                let formData = {
                    'id': this.selectedRecommendation.id,
                    'text': this.text,
                    'owner': this.selectedOwner
                }
                MeetingAgendaService.editMeetingAgendaRecommendation(formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateRecommendationSuccess'), 'success')
                        this.loadRecommendations()
                        this.CancelEditRecommendation()
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false

                })
            },
            CancelEditRecommendation() {
                this.selectedRecommendation = null
                this.selectedOwner = null
                this.text = ''

            },
            loadRecommendations() {
                MeetingAgendaService.getAgendaRecommendations(this.id).then((res) => {
                    this.recommendations = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteRecommendation(meetingAgendaRecommendationId) {
                this.CancelEditRecommendation()
                this.$refs.confirmDialog.open(this.$t('DeleteRecommendation'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            MeetingAgendaService.deleteAgendaRecommendation(meetingAgendaRecommendationId).then((res) => {
                                if (res) {
                                    this.loadRecommendations()
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            }
        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000;
    }
</style>