<template>
    <v-card tile class="elevation-0">
        <v-card-text>
            <v-form ref="activityPropsForm" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <v-simple-table>
                            <tr>
                                <th class="col-md-4">
                                    <v-text-field v-model="propName" dense :rules="rules" required counter="25" clearable :label="$t('PropertyName')" />
                                </th>
                                <th class="col-md-3">
                                    <v-select v-model="propTypeId" dense :items="dataTypes" :loading="loading" required :rules="rules" item-value="id" clearable item-text="name" :label="$t('PropertyType')" />
                                </th>
                                <th class="col-md-3">
                                    <v-text-field v-model="propValue" dense counter="25" :label="$t('Value')" />
                                </th>
                                <th class="col-md-1 text-center">
                                    <v-btn class="mt-2" :elevation="0" fab dark x-small color="teal" @click="addRow">
                                        <v-icon dark small>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </th>
                            </tr>
                            <tbody class="prop-table">
                                <tr v-for="(prop, index) in activityProps" :key="index">
                                    <td>
                                        <v-text-field v-model="prop.Name" dense :label="$t('PropertyName')" />
                                    </td>
                                    <td>
                                        <v-select v-model="prop.TypeId" :items="dataTypes" item-value="id" dense item-text="name" :label="$t('PropertyType')" />
                                    </td>
                                    <td>
                                        <v-text-field v-model="prop.Value" dense :label="$t('PropertyValue')" />
                                    </td>
                                    <td class="text-center">
                                        <v-icon dark color="error" @click="deleteRow(index)">
                                            mdi-trash-can-outline
                                        </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-divider class="mt-8" />
        <v-card-actions class="d-block text-end">
            <v-btn tile color="secondary" small class="white--text" @click="saveProperties">
                {{ $t('Save') }}
            </v-btn>
            <v-btn tile color="error" small class="white--text" @click="$emit('close')">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

    import LookupsService from '@/services/LookupsService.js'

    export default {

        props: {
            task: {
                type: Object,
                default: null
            },
        },

        data() {
            return {
                valid: true,
                loading: true,
                propName: '',
                propTypeId: 0,
                propValue: '',
                activityProps: this.task.ActivityProperties,
                dataTypes: [],
                rules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },

        created() {
            this.listDataTypes()
        },

        methods: {

            addRow: function () {
                if (this.$refs.activityPropsForm.validate()) {
                    let prop = { Name: this.propName, TypeId: this.propTypeId, Value: this.propValue }
                    this.propName = ''
                    this.propTypeId = 0
                    this.propValue = ''
                    this.activityProps.push(prop)
                    this.$refs.activityPropsForm.resetValidation()
                }
            },

            saveProperties() {
                this.$emit('update', this.activityProps)
            },

            deleteRow(index) {
                this.activityProps.splice(index, 1)
            },

            listDataTypes() {
                this.loading = true
                LookupsService.listDataTypes()
                    .then(res => {
                        this.dataTypes = res.data.map(function (obj) {
                            return { id: parseInt(obj.id), name: obj.name }
                        })
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    }
</script>
<style>
    .prop-table tr:hover {
        background: none !important;
    }

    .prop-table tr td {
        border: 0 !important
    }
</style>