<template>
    <v-container fluid>
        <v-row>
            <!-- Online Users List -->
            <v-col cols="4" class="py-0">
                <v-card style="height: 90vh;" class="rounded-lg">
                    <v-card-title>
                        {{ $t('Messages')}}
                        <v-badge color="red" class="ms-2"
                                 content="6">
                        </v-badge>
                    </v-card-title>
                    <v-divider class="custom-separator" />
                    <v-card-text>
                        <v-autocomplete v-model="selectedChat" class="rounded-xl grey  border-0 lighten-5 mt-1" hide-details
                                        :items="userList"
                                        item-text="name"
                                        item-value="id"
                                        outlined
                                        dense
                                        clearable
                                        return-object
                                        :placeholder="$t('Search')"
                                        append-icon="mdi-magnify" />
                    </v-card-text>

                    <!-- Search and Last Chats -->

                    <v-list three-lines>
                        <v-list-item-group>
                            <template v-for="(chat) in chatsList">
                                <v-list-item v-show="chat.updatedAt" :key="chat.id" @click="loadChat(chat)" color="primary">
                                    <v-btn color="#fff" fab class="me-4 d-inline-flex justify-center align-center position-relative">
                                        <v-badge small :color="userStatus(chat) ? 'green' : 'error'" bordered bottom dot left>
                                            <v-icon size="35" color="primary">
                                                mdi-account-outline
                                            </v-icon>
                                        </v-badge>
                                    </v-btn>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ chat.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="my-2">{{ chat.lastMessage??"" }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-badge v-if="chat.unreadMessages" color="error" :content="chat.unreadMessages" />
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider class="custom-separator" :key="'divider' + chat.id" v-show="chat.updatedAt" />
                            </template>
                        </v-list-item-group>
                    </v-list>

                </v-card>
            </v-col>
            
            <v-col cols="8" class="pt-0">
                <v-scroll-y-transition mode="out-in">
                    <v-card v-if="openedChat" tile class="chat-box d-flex flex-column rounded-lg" color="#fff" style="height: 89vh;">
                        <v-card flat tile class="elevation-0 rounded-lg" style="border-radius: 0;">
                            <v-container fluid>
                                <v-row align="center">
                                    <v-col cols="auto">
                                        <v-btn color="#fff" small fab class=" mx-2 d-inline-flex justify-center align-center position-relative">
                                            <v-icon color="primary">
                                                mdi-account-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-col>

                                    <v-col>
                                        <span class="subheading">{{ openedChat.name }}</span>
                                        <v-icon v-show="userStatus(openedChat)" small class="ml-1" color="green">mdi-record</v-icon>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-divider class="custom-separator" />
                        <!-- Messages container -->
                        <v-card-text ref="messagesContainer" class="messages-container overflow-y-auto " style="flex-grow: 1;">
                            <template v-for="(msg) in messages">
                                <div :key="msg.id" :class="{ 'message me': !msg.me, 'message': msg.me }">
                                    <div label :style="{background: msg.me ? '#fbfbfb' : '#f1f1f1' }" class="mb-1 py-4 chat-chip">
                                        {{ msg.messageText }}
                                    </div>
                                    <sub class="grey--text mt-1 mb-5" style="font-size: 0.7rem;">
                                        {{ msg.sentAt | formatDateWithTimeGregorian }}
                                    </sub>
                                </div>
                            </template>
                        </v-card-text>
                        <v-card>
                            <v-card-text class=" d-flex align-center">
                                <v-textarea v-model="content"
                                            :placeholder="$t('TypeMessage')"
                                            no-details
                                            auto-grow
                                            outlined
                                            rows="1"
                                            class="message-input "
                                            hide-details
                                            @keyup.enter="sendMessage" />

                                <v-btn icon
                                       color="primary"
                                       :disabled="!content.trim()"
                                       @click="sendMessage">
                                    <v-icon>mdi-send {{isArabicLanguage ? 'mdi-flip-h' : ''}} </v-icon>
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-card>

                    <v-card v-else>
                        <v-card-text class="text-center">
                            <img src="@/assets/images/empty.png" width="50%">
                            <div>{{ $t('NoItemsToShow') }}</div>
                        </v-card-text>
                    </v-card>
                </v-scroll-y-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ChatsService from '../services/ChatsService'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import { mapGetters } from 'vuex'

    export default {
        components: {
        },
        props: {
        },
        data() {
            return {
                chatsList: [],
                selectedChat: null,
                openedChat: null,
                searchText: '',
                loading: false,
                userList: [],
                userName: 'M M',
                selected: [2],
                selectedUser: null,
                users: [],
                content: '',
                messages: [

                ],
                search: '',

            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

            isArabicLanguage() {
                return this.language == 'ar'
            },
            currentUserName() {
                return this.isArabicLanguage ? this.loggedInUser?.fullnameAr : this.loggedInUser?.fullnameEn
            },

        },
        watch: {

            selectedChat(value) {
                if (value.chatId == 0) {
                    this.createChat(value)
                    return
                }
                if (!this.openedChat || this.openedChat.id != value.chatId) {
                    let filteredChat = this.chatsList.find(x => x.id == value.chatId)
                    this.loadChat(filteredChat)
                }


            },


        },


        created() {
            this.listOnlineUsers()
            this.listChats()
            this.listUsersForSearch()
        },
        mounted() {
            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent('UserOnline', this.listOnlineUsers)
                subscribeToEvent('ChatChanges', () => {
                    if (this.openedChat) {
                        this.loadChat(this.openedChat, true)
                    } else {
                        this.listChats()
                    }

                })
            }).catch((err) => {
                console.log('Error establishing SignalR connection:', err)
            })
            

        },
        beforeDestroy() {
            unsubscribeFromEvent('UserOnline')
            unsubscribeFromEvent('ChatChanges')

            this.openedChat = null
        },
        methods: {
            listChats() {
                ChatsService.listUserChats().then(res => {
                    if (res.data) {
                        this.chatsList = res.data

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            listUsersForSearch() {

                this.loading = true
                ChatsService.searchUsersAndChats().then(res => {
                    if (res.data) {
                        this.userList = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

            },


            userStatus(chat) {
                return this.users.some(u => u.id == chat.chatMembers[0].userId)
            },
            createChat(chat) {
                this.messages = []
                let formData = {
                    'isGroup': false,
                    'usersIds': [chat.userId],
                    'name': '',
                }
                ChatsService.createChat(formData).then(newChatRes => {
                    if (newChatRes.success) {
                        this.chatsList.push(newChatRes.data)
                        this.openedChat = newChatRes.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                    this.content = ''

                })
            },
            loadChat(chat, reloadChats) {


                this.openedChat = chat
                this.messages = []
                ChatsService.loadChatDetails(chat.id).then(res => {
                    if (res.data) {
                        this.messages = res.data
                        chat.unreadMessages = 0
                        this.scrollToBottom()
                        if (reloadChats) {
                            this.listChats()
                        }
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

            },

            sendMessage() {
                this.messages.push({ messageText: this.content, me: true, sentAt: '' })
                this.scrollToBottom()
                let formData = {
                    'chatId': this.openedChat.id,
                    'messageText': this.content,
                }
                ChatsService.sendMessage(formData).then(res => {
                    if (res.success) {
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                    this.content = ''

                })
            },

            listOnlineUsers() {
                ChatsService.listOnlineUsers().then(res => {
                    if (res.data) {
                        this.users = res.data
                        if (this.users.length > 0) {
                            this.userName = this.users[0].name
                        }
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            getUserInitials(name) {
                const namesArray = name.trim().split(/\s+/)
                const initials = namesArray.length > 1
                    ? (namesArray[0][0] + ' ' + namesArray[namesArray.length - 1][0])
                    : namesArray[0][0]
                return initials ? initials.toUpperCase() : ''
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    const container = this.$refs.messagesContainer
                    if (container) {
                        container.scrollTop = container.scrollHeight
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .v-list-item-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        height: 40px;
        width: 40px;
        font-size: 1.25rem;
    }

    .messages-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

        .message.me {
            align-items: flex-end;
        }

    .position-relative {
        position: relative;
    }

    .status-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: white;
        border-radius: 50%;
        transform: translate(50%, 50%);
    }

    .hidden-icon {
        visibility: hidden;
    }

    .chat-box {
        background: #e7ebef;
        background-size: contain;
        background-repeat: repeat;
    }

    .chat-chip {
        display: block;
        word-wrap: break-word;
        padding: 5px 10px;
        border-radius: 10px;
        color: #000;
        font-size: 15px
    }
</style>

